import { TextField } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Pagination from '@material-ui/lab/Pagination';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { isMacOs } from 'react-device-detect';

import { userId } from '../../../_helpers/useful-functions';
import { useAppSelector } from '../../../store';
import { getSearch, setAppCardToClose, setClearSearchData } from '../../../store/features/hr/hrSlice';

const SearchModal = ({ setSearchOpenModal, searchOpenModal }: any) => {
    const { words } = useAppSelector(state => state.localization);
    const [searchValue, setSearchValue] = useState<string>('');
    const [searchValueRef, setSearchValueRef] = useState<string>('');
    const [searchValueLoading, setSearchValueLoading] = useState<boolean>(true);
    const [searchPopulerHover, setSearchPopulerHover] = useState<number>(0);
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();

    const { searchDetail }: any = useAppSelector(state => state.hr);
    const { urlName }: any = useAppSelector(state => state.hrDetails);
    const ref: any = useRef();
    const popularSearchTitle = [
        {
            titleLogin: words['WEB.popularSearchFirstTitle'],
            titleLogout: words['WEB.popularSearchFirstLoginOutUrlTitle'],
            loginUrl: words['WEB.popularSearchFirstUrl'],
            loginOutUrl: words['WEB.popularSearchFirstLoginOutUrl'],
        },
        {
            titleLogin: words['WEB.popularSearchSecondTitle'],
            titleLogout: words['WEB.popularSearchSecondLoginOutUrlTitle'],
            loginUrl: words['WEB.popularSearchSecondUrl'],
            loginOutUrl: words['WEB.popularSearchSecondLoginOutUrl'],
        },
        {
            titleLogin: words['WEB.popularSearchThirdTitle'],
            titleLogout: words['WEB.popularSearchThirdLoginOutUrlTitle'],
            loginUrl: words['WEB.popularSearchThirdUrl'],
            loginOutUrl: words['WEB.popularSearchThirdLoginOutUrl'],
        },
    ];

    const handleSearch = e => {
        setSearchValue(e.target.value);
    };
    const onClickSearch = async () => {
        setPage(1);
        setSearchValueRef(ref?.current?.value);
        await dispatch(getSearch({ searchValue: searchValue, pageIndex: page - 1 }));
        setSearchValueLoading(false);
    };

    const handleDelete = () => {
        setSearchValueLoading(true);
        setSearchValueRef('');
        setSearchValue('');
        dispatch(setClearSearchData());
    };

    const handleClose = () => {
        setSearchOpenModal(false);
        setSearchValue('');
        setSearchValueLoading(true);
        setSearchValueRef('');
        dispatch(setClearSearchData());
        dispatch(setAppCardToClose(false));
    };

    useEffect(() => {
        dispatch(setClearSearchData());
    }, []);

    useEffect(() => {
        if (!searchValueLoading) {
            dispatch(getSearch({ searchValue: searchValue, pageIndex: page - 1 }));
        }
    }, [page]);

    const searchEnter = e => {
        setPage(1);
        if (e.keyCode == 13 && searchValue.length > 0) {
            setSearchValueLoading(false);
            setSearchValueRef(ref?.current?.value);
            dispatch(getSearch({ searchValue: e.target.value, pageIndex: page - 1 }));
        }
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const handleSearchIndex = index => {
        setSearchPopulerHover(index);
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={isMacOs ? "modal-06 isMacOs" : "modal-06"}
            open={searchOpenModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={searchOpenModal}>
                <div className={searchDetail?.items?.items?.length > 0 ? 'modal-content f-active' : 'modal-content'}>
                    <button className="search-close-icon" onClick={handleClose}>
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="48" height="48" rx="24" fill="black" />
                            <g clipPath="url(#clip0_4028_10926)">
                                <path
                                    d="M31 18.41L29.59 17L24 22.59L18.41 17L17 18.41L22.59 24L17 29.59L18.41 31L24 25.41L29.59 31L31 29.59L25.41 24L31 18.41Z"
                                    fill="white"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_4028_10926">
                                    <rect width="24" height="24" fill="white" transform="translate(12 12)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </button>
                    <div className="c-wrapper">
                        <TextField
                            onKeyDown={searchEnter}
                            value={searchValue}
                            inputRef={ref}
                            onChange={e => handleSearch(e)}
                            InputProps={{
                                startAdornment: (
                                    <svg
                                        className="search-icon"
                                        width="30"
                                        height="30"
                                        viewBox="0 0 30 30"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M20.8333 18.3333H19.5167L19.05 17.8833C20.6833 15.9833 21.6667 13.5167 21.6667 10.8333C21.6667 4.85 16.8167 0 10.8333 0C4.85 0 0 4.85 0 10.8333C0 16.8167 4.85 21.6667 10.8333 21.6667C13.5167 21.6667 15.9833 20.6833 17.8833 19.05L18.3333 19.5167V20.8333L26.6667 29.15L29.15 26.6667L20.8333 18.3333ZM10.8333 18.3333C6.68333 18.3333 3.33333 14.9833 3.33333 10.8333C3.33333 6.68333 6.68333 3.33333 10.8333 3.33333C14.9833 3.33333 18.3333 6.68333 18.3333 10.8333C18.3333 14.9833 14.9833 18.3333 10.8333 18.3333Z"
                                            fill="black"
                                        />
                                    </svg>
                                ),

                                endAdornment: (
                                    <>
                                        {searchValue.length > 0 && (
                                            <>
                                                <button onClick={onClickSearch} className="c-item-search-button">
                                                    {words && words['WEB.search']}
                                                </button>
                                                <button>
                                                    <svg
                                                        onClick={() => handleDelete()}
                                                        className="c-item-search-delete"
                                                        width="14"
                                                        height="14"
                                                        viewBox="0 0 14 14"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                                                            fill="black"
                                                        />
                                                    </svg>
                                                </button>
                                            </>
                                        )}
                                    </>
                                ),
                            }}
                            placeholder={words && words['WEB.search']}
                        />

                        {searchValueLoading && (
                            <>
                                <span className="c-item-01">{words && words['WEB.popularSearch']}</span>
                                <div className="c-item-02">
                                    {popularSearchTitle?.map((item, index) => {
                                        return (
                                            <Link
                                                target="_blank"
                                                rel="noreferrer"
                                                to={{ pathname: userId ? item?.loginUrl : item?.loginOutUrl }}
                                                onMouseOver={() => handleSearchIndex(index)}
                                                key={'searchTitle' + index}
                                                className={index === searchPopulerHover ? 'c-item-02-A active' : 'c-item-02-A '}>
                                                {userId ? item?.titleLogin : item?.titleLogout}
                                            </Link>
                                        );
                                    })}
                                </div>
                            </>
                        )}
                        {searchValueLoading && (
                            <div className="search-responsive">
                                <span className="c-item-01">{words && words['WEB.popularSearch']}</span>
                                <Swiper spaceBetween={15} slidesPerView={2} scrollbar={{ draggable: true }}>
                                    {popularSearchTitle.map((item, index) => {
                                        return (
                                            <SwiperSlide key={'searchTitle' + index}>
                                                <Link
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    to={{ pathname: userId ? item?.loginUrl : item?.loginOutUrl }}
                                                    onMouseOver={() => handleSearchIndex(index)}
                                                    className={index === searchPopulerHover ? 'c-item-02-A active' : 'c-item-02-A '}>
                                                    {userId ? item?.titleLogin : item?.titleLogout}
                                                </Link>
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>
                            </div>
                        )}
                        <div className="c-item-03">
                            {!searchDetail?.loading &&
                                !searchValueLoading &&
                                (searchDetail?.items?.items?.length > 0 ? (
                                    <div className="c-item-03-A">
                                        <strong className="c-unit-01">{`"${searchValueRef}"`}</strong> {words && words['WEB.for']}{' '}
                                        {searchDetail?.items?.totalCount} {words && words['WEB.searchFounded']}
                                    </div>
                                ) : (
                                    <div className="c-item-03-D">
                                        <strong className="c-unit-01">{`"${searchValueRef}"`}</strong> {words && words['WEB.withNotFounded']}
                                    </div>
                                ))}
                            {searchDetail?.items?.items?.map((item, index) => {
                                return (
                                    <Link
                                        target={(item?.type === 4 || item?.type === 5) && '_blank'}
                                        onClick={() => handleClose()}
                                        to={{
                                            pathname:
                                                userId && (item?.type === 4 || item?.type === 5)
                                                    ? item?.urlWithSso
                                                    : item?.type === 1
                                                        ? '/' + (words && words['WEB.NEWS'])
                                                        : item?.type === 3
                                                            ? '/' + (words && words['WEB.TOBEKOC'])
                                                            :
                                                            item?.type === 4 && !userId
                                                                ? item?.urlShortName + "/" +
                                                                urlName?.items?.find(x => x.id === item?.url.split('/')[1])?.url
                                                                : '/' + item?.urlShortName + "/" +
                                                                urlName?.items?.find(x => x.id === item?.url.split('/')[1])?.url,
                                        }}
                                        key={'search' + index}>
                                        <div className="c-item-03-B">{item?.header}</div>
                                        <div className="c-item-03-C">/{item?.urlShortName}</div>
                                    </Link>
                                );
                            })}
                        </div>

                        <div className="pagination-wrapper">
                            {!searchValueLoading && searchDetail?.items?.items?.length > 0 && (
                                <Pagination count={searchDetail.items?.totalPages} page={page} onChange={handlePageChange} shape="rounded" />
                            )}
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default SearchModal;
