import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import 'swiper/modules/autoplay/autoplay.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';
import 'swiper/swiper.scss';

import { userId } from '../../_helpers/useful-functions';
import ToBeKocResponsive from '../../components/details/TobeKocResponsive';
import { useAppSelector } from '../../store';
import { getApplicationList, getNewsList, getFifthPart } from '../../store/features/hr/hrSlice';
import { getLeftMenuList, getToBeKocDetails, getUrlName } from '../../store/features/hrDetail/hrDetailsSlice';
import ArrowHead from './ArrowHead';
import HeroSlider from './HeroSlider';
import LandingSectionNew from './LandingSectionNew';
import ResponsiveSliderApps from './ResponsiveSliderApps';
import SliderApps from './SliderApps';
import TheNews from './TheNews';
import { getLocalStorageItem } from '../../core/utility';
import VideoURLFrame from '../../components/details/VideoURLFrame';

const Home = () => {
    const dispatch = useDispatch();
    const { landingPageList } = useAppSelector(state => state.hr);
    const toBeKocDetails = useAppSelector(state => state.hrDetails.toBeKocDetails);
    const [windowSize, setWindowSize] = useState({ width: undefined });
    const culture = getLocalStorageItem('SelectedLanguage');


    const browserLanguage = navigator.language || navigator.languages[0]
    let description = "";

    if (browserLanguage.includes('en')) {
        description = "One of the most significant reasons for being among the most desirable companies to work for in the world is our dedication to creating happier working environment. All employee experience applications at Koç Group are here. Visit İçinde Koç Var to discover the most innovative and modern employee experience applications at Koç Group."
    } else {
        description = "Dünyanın en çok çalışılmak istenen şirketleri arasında yer almamızın en önemli sebeplerinden biri de daha mutlu iş yerleri yaratmaya verdiğimiz önem. Koç’taki tüm çalışan deneyimi uygulamaları burada. İçinde Koç Var’ı ziyaret et, Koç’taki en yenilikçi ve modern çalışan deneyimi uygulamalarını keşfet."
    }


    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
            });
        }
        setWindowSize({ width: window.innerWidth });
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (landingPageList?.col1?.length > 0) {
            setTimeout(() => {
                dispatch(getNewsList());
                dispatch(getApplicationList());
                dispatch(getFifthPart());
                dispatch(getLeftMenuList());
                dispatch(getUrlName());
            }, 3000);
        }
    }, [landingPageList?.col1?.length > 0,culture]);

    useEffect(() => {


        if (!userId) {
            dispatch(getNewsList());
            dispatch(getApplicationList());
            dispatch(getFifthPart());
            dispatch(getLeftMenuList());
            dispatch(getUrlName());
        }

        dispatch(getToBeKocDetails());

    }, [culture]);
    useEffect(() => {
        const rootHome = document.getElementById('root');

        if (userId) {
            rootHome.className = 'home';
        }

        return () => {
            rootHome.className = '';
        };
    }, []);

    return (
        <>
            <Helmet>
                <meta name="description" content={description} />
            </Helmet>
            {userId && <LandingSectionNew />}
            <HeroSlider />
            <ArrowHead />
            <TheNews />
            <SliderApps />
            <ResponsiveSliderApps />
            {windowSize.width > 768 && <VideoURLFrame isHomePage details={toBeKocDetails} />}
            { !userId && windowSize.width < 768 && <ToBeKocResponsive isHomePage details={toBeKocDetails} />}
        </>
    );
};

export default Home;