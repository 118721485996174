import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Modal from '@material-ui/core/Modal';
import Tooltip from '@material-ui/core/Tooltip';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getCookieValue, getLocalStorageItem, setLocalStorageItem } from '../../core/utility';
import { useAppSelector } from '../../store';
import { setCookieOptions } from '../../store/features/user/usersSlice';
import { IOSSwitch } from './iosSwitch';

const Cookies = () => {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [isAccept, setIsAccept] = useState<boolean>(false);
    const isOpenModal = getLocalStorageItem('OpenCookiesModal') ?? false;

    const { words } = useAppSelector(state => state.localization);
    const { cookiesOptions } = useAppSelector(state => state.users);
    const dispatch = useDispatch();
    const isMandatoryAccepted = getCookieValue('mandatoryAccepted')?.toLowerCase() == 'true'; //! check mandatory Value.
    const isGoogleAnalistAccepted = getCookieValue('googleAnalistAccepted')?.toLowerCase() === 'true';
    const isGoogleAdsenseAccepted = getCookieValue('googleAdsenseAccepted')?.toLowerCase() === 'true';
    const isFacebookPixelAccepted = getCookieValue('facebookPixelAccepted')?.toLowerCase() === 'true';

    //! delete cookies func.
    const deleteCookie = name => {
        document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    };
    //! create cookies func.
    const createCookiesNameValue = (name: string, value: string | boolean) => {
        document.cookie = name + `=${value}; Path=/; Expires=Thu, 01 Jan 2027 00:00:01 GMT;`;
    };

    useEffect(() => {
        setOpenModal(isOpenModal);
    }, [isOpenModal]);
    //!accept butonuna basıldıktan  cookie ye true/false değeri göndermek için
    useEffect(() => {
        if (isAccept) {
            //!mandatory cookie accepted!
            createCookiesNameValue('mandatoryAccepted', true);

            if (cookiesOptions?.googleAnalist || isGoogleAnalistAccepted) {
                createCookiesNameValue('googleAnalistAccepted', true);
            } else {
                createCookiesNameValue('googleAnalistAccepted', false);
            }

            if (cookiesOptions?.googleAdsense || isGoogleAdsenseAccepted) {
                createCookiesNameValue('googleAdsenseAccepted', true);
            } else {
                createCookiesNameValue('googleAdsenseAccepted', false);
            }
            if (cookiesOptions?.facebookPixel || isFacebookPixelAccepted) {
                createCookiesNameValue('facebookPixelAccepted', true);
            } else {
                createCookiesNameValue('facebookPixelAccepted', false);
            }
        }
    }, [isAccept]);

    useEffect(() => {
        //! <!-- Google adsense start -->
        if (isGoogleAdsenseAccepted || (isAccept && cookiesOptions?.googleAdsense)) {
            const scriptGoogleAdsense = document.createElement('script');
            scriptGoogleAdsense.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5554201037037514';
            scriptGoogleAdsense.async = true;
            scriptGoogleAdsense.crossOrigin = 'anonymous';
            document?.head?.appendChild?.(scriptGoogleAdsense);

            //! end Google adsense end -->
        } else {
            deleteCookie('__gpi');
            deleteCookie('__gads');
        }
        if (isFacebookPixelAccepted || (isAccept && cookiesOptions?.facebookPixel)) {
            //! Facebook Pixcel scriptini oluştur
            const scriptFacebookPixel = document.createElement('script');
            scriptFacebookPixel.innerHTML = `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '2246250615763475');
                fbq('track', 'PageView');
            `;
            //! Facebook Piksel için noscript elementini oluştur
            const noscriptFacebookPixel = document.createElement('noscript');
            const noscriptImageElement = document.createElement('img');
            noscriptImageElement.setAttribute('src', 'https://www.facebook.com/tr?id=2246250615763475&ev=PageView&noscript=1');
            noscriptFacebookPixel.appendChild(noscriptImageElement);
            //! Facebook Piksel script ve noscript elementlerini sayfaya ekle
            document.body.appendChild(scriptFacebookPixel);
            document.body.appendChild(noscriptFacebookPixel);
        } else {
            deleteCookie('_fbp');
        }
    }, [isFacebookPixelAccepted, isGoogleAnalistAccepted, isGoogleAdsenseAccepted, isAccept]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setCookieOptions({ ...cookiesOptions, [event.target.name]: event.target.checked }));

        if (event.target.name === 'googleAnalist') {
            createCookiesNameValue('googleAnalistAccepted', event.target.checked);
        }
        if (event.target.name === 'googleAdsense') {
            createCookiesNameValue('googleAdsenseAccepted', event.target.checked);
        }
        if (event.target.name === 'facebookPixel') {
            createCookiesNameValue('facebookPixelAccepted', event.target.checked);
        }
    };
    const handleOpenModal = () => {
        setLocalStorageItem('OpenCookiesModal', true);
        setOpenModal(true);
    };
    const handleAcceptCookies = (type = '') => {
        //!set all cookies here....
        if (type === 'all') {
            dispatch(
                setCookieOptions({
                    googleAnalist: true,
                    mandatory: true,
                    googleAdsense: true,
                    facebookPixel: true,
                    //!add new cookies here to all value true
                }),
            );

            createCookiesNameValue('mandatoryAccepted', true);
            createCookiesNameValue('googleAnalistAccepted', true);
            createCookiesNameValue('googleAdsenseAccepted', true);
            createCookiesNameValue('facebookPixelAccepted', true);
        }
        createCookiesNameValue('mandatoryAccepted', true);
        setIsAccept(true);
        setLocalStorageItem('OpenCookiesModal', false);
        setOpenModal(false);
    };
return null
    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal-07"
                open={openModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 10,
                }}>
                <Fade in={openModal}>
                    <div className="c-wrapper">
                        <div className="modal-content">
                            <div className="c-table-container">
                                <h6 className="c-item-01">{words && words['WEB.cookiesManageOptions']}</h6>
                                <hr />
                                <div className="c-item-02">
                                    <FormControl component="fieldset">
                                        <FormGroup>
                                            <FormControlLabel
                                                label={words && words['WEB.cookiesMandatory']}
                                                control={
                                                    <div className="icon-group">
                                                        <Tooltip
                                                            id="cookie-mandatory"
                                                            placement="left"
                                                            className="tooltip-02"
                                                            title="Zorunlu"
                                                            enterDelay={200}
                                                            leaveDelay={200}>
                                                            <svg
                                                                width="12px"
                                                                height="15px"
                                                                viewBox="0 0 12 15"
                                                                version="1.1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                xmlnsXlink="http://www.w3.org/1999/xlink">
                                                                {/* <title>Icons/Material/Svg/24/lock</title> */}
                                                                <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                    <g
                                                                        id="HRedition--cookies-popup-2"
                                                                        transform="translate(-974.000000, -573.000000)"
                                                                        fill="#000000">
                                                                        <g id="Group-53-Copy" transform="translate(367.000000, 200.000000)">
                                                                            <g
                                                                                id="Icons/Material/Svg/24/lock"
                                                                                transform="translate(607.656250, 373.656250)">
                                                                                <path
                                                                                    d="M9.34375,14 C9.69791667,14 10.0104167,13.8697917 10.28125,13.609375 C10.5520833,13.3489583 10.6875,13.0416667 10.6875,12.6875 L10.6875,12.6875 L10.6875,6 C10.6875,5.64583333 10.5520833,5.33854167 10.28125,5.078125 C10.0104167,4.81770833 9.69791667,4.6875 9.34375,4.6875 L9.34375,4.6875 L8.6875,4.6875 L8.6875,3.34375 C8.6875,2.42708333 8.359375,1.640625 7.703125,0.984375 C7.046875,0.328125 6.26041667,0 5.34375,0 C4.42708333,0 3.640625,0.328125 2.984375,0.984375 C2.328125,1.640625 2,2.42708333 2,3.34375 L2,3.34375 L2,4.6875 L1.34375,4.6875 C0.989583333,4.6875 0.677083333,4.81770833 0.40625,5.078125 C0.135416667,5.33854167 0,5.64583333 0,6 L0,6 L0,12.6875 C0,13.0416667 0.135416667,13.3489583 0.40625,13.609375 C0.677083333,13.8697917 0.989583333,14 1.34375,14 L1.34375,14 L9.34375,14 Z M7.40625,4.6875 L3.28125,4.6875 L3.28125,3.34375 C3.28125,2.78125 3.484375,2.296875 3.890625,1.890625 C4.296875,1.484375 4.78125,1.28125 5.34375,1.28125 C5.90625,1.28125 6.390625,1.484375 6.796875,1.890625 C7.203125,2.296875 7.40625,2.78125 7.40625,3.34375 L7.40625,3.34375 L7.40625,4.6875 Z M5.34375,10.6875 C4.98958333,10.6875 4.67708333,10.5520833 4.40625,10.28125 C4.13541667,10.0104167 4,9.69791667 4,9.34375 C4,8.98958333 4.13541667,8.67708333 4.40625,8.40625 C4.67708333,8.13541667 4.98958333,8 5.34375,8 C5.69791667,8 6.01041667,8.13541667 6.28125,8.40625 C6.55208333,8.67708333 6.6875,8.98958333 6.6875,9.34375 C6.6875,9.69791667 6.55208333,10.0104167 6.28125,10.28125 C6.01041667,10.5520833 5.69791667,10.6875 5.34375,10.6875 Z"
                                                                                    id="lock"></path>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </Tooltip>
                                                        <IOSSwitch checked={cookiesOptions?.mandatory || isMandatoryAccepted} name="mandatory" />
                                                    </div>
                                                }
                                            />
                                            <FormControlLabel
                                                label={words && words['WEB.cookiesGoogleAnalisticCookies']}
                                                control={
                                                    <IOSSwitch
                                                        checked={cookiesOptions?.googleAnalist || isGoogleAnalistAccepted}
                                                        onChange={handleChange}
                                                        name="googleAnalist"
                                                    />
                                                }
                                            />
                                            <FormControlLabel
                                                label={words && words['WEB.cookiesGoogleAdsenseCookies']}
                                                control={
                                                    <IOSSwitch
                                                        checked={cookiesOptions?.googleAdsense || isGoogleAdsenseAccepted}
                                                        onChange={handleChange}
                                                        name="googleAdsense"
                                                    />
                                                }
                                            />
                                            <FormControlLabel
                                                label={words && words['WEB.cookiesGoogleFacebookCookies']}
                                                control={
                                                    <IOSSwitch
                                                        checked={cookiesOptions?.facebookPixel || isFacebookPixelAccepted}
                                                        onChange={handleChange}
                                                        name="facebookPixel"
                                                    />
                                                }
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </div>
                                <hr />
                                <div className="c-item-03">
                                    <button type="button" className="c-unit-01" onClick={() => handleAcceptCookies()}>
                                        <span className="c-unit-01-A">{words && words['WEB.cookiesSaveOptions']}</span>
                                        <svg
                                            className="c-unit-01-B"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="7.566"
                                            height="13.941"
                                            viewBox="0 0 7.566 13.941">
                                            <path
                                                fill="#ee5545"
                                                d="M.548-12.86l-.221.221a.375.375 0 0 0 0 .53L6.436-6 .327.109a.375.375 0 0 0 0 .53L.548.86a.375.375 0 0 0 .53 0l6.6-6.6a.375.375 0 0 0 0-.53l-6.6-6.6a.375.375 0 0 0-.53.01z"
                                                transform="translate(-0.217 12.97)"
                                            />
                                        </svg>
                                    </button>
                                    <button type="button" onClick={() => handleAcceptCookies('all')} className="c-unit-02">
                                        {words && words['WEB.cookiesAcceptAll']}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
            {!isMandatoryAccepted && (
                <div id="cookieAcceptBar" className="cookie-accept-bar">
                    <div className="c-wrapper">
                        <div className="c-container">
                            <div className="c-item-01">
                                <h6 className="c-unit-01">{words && words['WEB.cookiesPrivacyOptionsCenter']}</h6>
                                <p className="c-unit-02">
                                    {words && words['WEB.cookiesUseText1']}
                                    <a
                                        className="c-unit-02-A"
                                        href="https://khicindekocvar.blob.core.windows.net/cdn/prod/KH_icindeKocVar_Cerez_Aydinlatma_Metni.pdf"
                                        // to={{ pathname: culture === 'tr-TR' ? '/cerez' : '/cookie' }}
                                        target="_blank"
                                        rel="noreferrer">
                                        {words && words['WEB.cookiesExplainText']}
                                    </a>
                                    {words && words['WEB.cookiesUseText2']}
                                </p>
                            </div>
                            <button type="button" className="c-item-02" onClick={handleOpenModal}>
                                <span className="c-unit-01">{words && words['WEB.cookiesManageOptions']}</span>
                                <svg
                                    className="c-unit-02"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="7.566"
                                    height="13.941"
                                    viewBox="0 0 7.566 13.941">
                                    <path
                                        fill="#ee5545"
                                        d="M.548-12.86l-.221.221a.375.375 0 0 0 0 .53L6.436-6 .327.109a.375.375 0 0 0 0 .53L.548.86a.375.375 0 0 0 .53 0l6.6-6.6a.375.375 0 0 0 0-.53l-6.6-6.6a.375.375 0 0 0-.53.01z"
                                        transform="translate(-0.217 12.97)"
                                    />
                                </svg>
                            </button>
                            <button type="button" onClick={() => handleAcceptCookies('all')} className="c-item-03">
                                {words && words['WEB.cookiesAcceptAll']}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {!isMandatoryAccepted && (
                <div id="cookieAcceptBar" className="cookie-accept-bar-responsive">
                    <div className="c-wrapper">
                        <div className="c-container">
                            <div className="c-item-01">
                                <h6 className="c-unit-01">{words && words['WEB.cookiesPrivacyOptionsCenter']}</h6>
                                <p className="c-unit-02">
                                    {words && words['WEB.cookiesUseText1']}
                                    <a
                                        className="c-unit-02-A"
                                        href="https://khicindekocvar.blob.core.windows.net/cdn/prod/KH_icindeKocVar_Cerez_Aydinlatma_Metni.pdf"
                                        // to={{ pathname: culture === 'tr-TR' ? '/cerez' : '/cookie' }}
                                        target="_blank"
                                        rel="noreferrer">
                                        {words && words['WEB.cookiesExplainText']}
                                    </a>
                                    {words && words['WEB.cookiesUseText2']}
                                </p>
                            </div>

                            <div className="button-responsive">
                                <button type="button" className="c-item-02" onClick={handleOpenModal}>
                                    <span className="c-unit-01">{words && words['WEB.cookiesManageOptions']}</span>
                                    <svg
                                        className="c-unit-02"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="7.566"
                                        height="13.941"
                                        viewBox="0 0 7.566 13.941">
                                        <path
                                            fill="#ee5545"
                                            d="M.548-12.86l-.221.221a.375.375 0 0 0 0 .53L6.436-6 .327.109a.375.375 0 0 0 0 .53L.548.86a.375.375 0 0 0 .53 0l6.6-6.6a.375.375 0 0 0 0-.53l-6.6-6.6a.375.375 0 0 0-.53.01z"
                                            transform="translate(-0.217 12.97)"
                                        />
                                    </svg>
                                </button>
                                <button type="button" onClick={() => handleAcceptCookies('all')} className="c-item-03">
                                    {words && words['WEB.cookiesAcceptAll']}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export default Cookies;
