import React, { useEffect, useState } from 'react';
import { isMacOs } from 'react-device-detect';
import { useDispatch } from 'react-redux';

import footerResponsive from '../../../_assets/img/footer-koc-logo.svg';
import { userId } from '../../../_helpers/useful-functions';
import { useAppSelector } from '../../../store';
import { getUserKvkk, setOpenAccordionCookiesOptions, setOpenCookiesModal } from '../../../store/features/user/usersSlice';
import WeatherWidget from '../../weather';
import KvkkModal from '../../../pages/home/KvkkModal';

const Footer = () => {
    const { footerList }: any = useAppSelector(state => state.hr);
    const { words } = useAppSelector(state => state.localization);
    const contactList = footerList?.items?.contacts;
    const dispatch = useDispatch();
    const { info }: any = useAppSelector(state => state.users);
    const { urlName }: any = useAppSelector(state => state.hrDetails);

    const [openKvkkModal, setOpenKvkkModal] = useState<boolean>(false);

    const createCookiesNameValue = (name: string, value: string | boolean) => {
        document.cookie = name + `=${value}; Path=/; Expires=Thu, 01 Jan 2027 00:00:01 GMT;`;
    };

    const handleClickOpenCookieModal = _index => {
        if (_index === 1) {
            dispatch(setOpenCookiesModal(true));
            dispatch(setOpenAccordionCookiesOptions(false));
            createCookiesNameValue('mandatoryAccepted', false);
        }
    };

    useEffect(() => {
        if (userId)
            dispatch(getUserKvkk());
    }, [userId])

    const checkApproveKvkk = (url: string) => {
        if (!info?.userInfo || info?.userInfo?.approveKvkk) {
            window.open(url, '_blank').focus();
        } else {
            setOpenKvkkModal(true);
        }
    }

    const detailsWithData = footerList?.items?.leftMenuDetails?.filter(item => item?.details?.length > 0) || [];
    const detailsWithoutData = footerList?.items?.leftMenuDetails?.filter(item => item?.details?.length === 0) || [];


    return (
        <footer className={isMacOs ? 'footer-01 isMacOs' : 'footer-01'}>
            {openKvkkModal && (
                <KvkkModal setOpenKvkkModal={setOpenKvkkModal} openKvkkModal={openKvkkModal} />
            )}
            <div className="c-wrapper">
                <a href="https://www.koc.com.tr/" target="_blank" rel="noreferrer" className="c-container-01">
                    <img
                        loading="lazy"
                        className="footer-logo"
                        src="https://khicindekocvar.blob.core.windows.net/cdn/prod/footer-left-koc-logo.jpg"
                        alt="logo"
                    />
                    <img
                        loading="lazy"
                        className="mobil-footer-logo"
                        src="https://khicindekocvar.blob.core.windows.net/cdn/prod/footer-left-koc-logo.jpg"
                        alt="logo"
                    />
                </a>
                <div className="c-container-02 d-none">
                    <h6 className="c-item-01">{words && words['WEB.corporation']}</h6>
                    {footerList?.items?.corporation?.length > 0 &&
                        footerList?.items?.corporation?.map((item, index) => (
                            <>
                                {item?.key === 'WEB.Kvkk' ? (
                                    <span
                                        key={'corporation-' + item?.title}
                                        onClick={() => checkApproveKvkk(item?.value)}
                                        className="c-item-02"
                                        role="button"
                                    >
                                        {item?.title}
                                    </span>
                                )
                                    :
                                    <a
                                        key={'corporation-' + item?.title}
                                        onClick={() => handleClickOpenCookieModal(index)}
                                        className="c-item-02"
                                        target={index === 0 ? '_blank' : ''}
                                        href={
                                            index === 0 ? item?.value : '#!'
                                        }
                                        rel="noreferrer">
                                        {item?.title}
                                    </a>
                                }
                            </>

                        ))}
                    {userId && (
                        <div id="weather-d-none">
                            <WeatherWidget />
                        </div>
                    )}
                </div>

                <div className='row mt-5'>
                    {detailsWithData.length > 0 &&
                        detailsWithData.map(item => (
                            <div className={`${item?.details?.length > 7 ? "col-12 col-lg-3  mr-3" : "col-12 col-lg-2"} c-container-03`} key={item.id}>
                                <h6 className="c-item-01">
                                    <a href={item?.leftMenuType === 0 ? `/${words && words['WEB.appDetails']}/${urlName?.items?.find(urlItem => urlItem.id === item.id)?.url}` : item?.url}>
                                        {item?.name}
                                    </a>
                                </h6>
                                <div className='detail-container'>
                                    {item?.details?.reduce((acc, elem, index) => {
                                        const chunkIndex = Math.floor(index / 7);
                                        if (!acc[chunkIndex]) {
                                            acc[chunkIndex] = [];
                                        }
                                        acc[chunkIndex].push(elem);
                                        return acc;
                                    }, []).map((chunk, index) => (
                                        <div className='detail-items' key={index}>
                                            {chunk.map(elem => (
                                                <a
                                                    className="c-item-02"
                                                    href={
                                                        elem?.leftMenuType === 6
                                                            ? `/${words && words['WEB.cardDetails']}/${urlName?.items?.find(urlItem => urlItem.id === elem.id)?.url}`
                                                            : elem?.url
                                                    }
                                                    rel="noreferrer"
                                                    key={elem.id}
                                                >
                                                    {elem?.name}
                                                </a>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))
                    }
                    {detailsWithoutData.length > 0 &&
                        <div className="col-12 col-lg-2 c-container-03 mr-5">
                            {detailsWithoutData.map(item => (
                                <h6 className="c-item-01" key={item.id}>
                                    <a href={item?.leftMenuType === 0 ? `/${words && words['WEB.appDetails']}/${urlName?.items?.find(urlItem => urlItem.id === item.id)?.url}` : item?.url}>
                                        {item?.name}
                                    </a>
                                </h6>
                            ))}
                        </div>
                    }
                    <div className="col-12 col-lg-2 c-container-05">
                        <div>
                            {userId && (
                                <>
                                    <h6 className="c-item-01 contactUs">{words && words['WEB.contactUs']}</h6>
                                    <div className='contactInfo'>
                                        <a
                                            className="c-item-02"
                                            href={'tel:' + contactList?.find(x => x?.key === 'WEB.Telephone')?.value}
                                            target="_blank"
                                            rel="noreferrer">
                                            {contactList?.find(x => x?.key === 'WEB.Telephone')?.title}
                                        </a>

                                        <a className="c-item-02" href={contactList?.find(x => x?.key === 'WEB.Whatsapp')?.value} target="_blank" rel="noreferrer">
                                            {contactList?.find(x => x?.key === 'WEB.Whatsapp')?.title}
                                        </a>
                                        <a
                                            className="c-item-02"
                                            href={'mailto:' + contactList?.find(x => x?.key === 'WEB.Mail')?.value}
                                            target="_blank"
                                            rel="noreferrer">
                                            {contactList?.find(x => x?.key === 'WEB.Mail')?.title}
                                        </a>
                                    </div>
                                    <hr/>
                                </>
                            )}
                            <div className="c-item-01">
                                {footerList?.items?.socialMedia?.length > 0 &&
                                    footerList?.items?.socialMedia?.map(item => (
                                        <a key={item?.key} href={item?.value} className="c-item" target="_blank" rel="noreferrer">
                                            <img loading="lazy" className="c-item-01" src={item?.iconUrl} alt={item.key} />
                                        </a>
                                    ))}
                            </div>

                            <div className="c-item-02">
                                <img
                                    loading="lazy"
                                    className="footer-logo-right"
                                    src="https://khicindekocvar.blob.core.windows.net/cdn/prod/footer-koc-logo.png"
                                    alt="logo"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <img loading="lazy" className="mobil-footer-logo-bottom" src={footerResponsive} alt="logo" />
            </div>

            <div className="c-coloumn">

                <div className='c-wrapper'>
                    {footerList?.items?.corporation?.length > 0 &&
                        footerList?.items?.corporation?.map((item, index) => (
                            <div className='c-item-01' key={"corporationContainer" + item?.title}>
                                {item?.key === 'WEB.Kvkk' ? (
                                    <span
                                        key={'corporation-' + item?.title}
                                        onClick={() => checkApproveKvkk(item?.value)}
                                        className="c-item-02"
                                        role="button"
                                    >
                                        {item?.title}
                                    </span>
                                )
                                    :
                                    <a
                                        key={'corporation-' + item?.title}
                                        onClick={() => handleClickOpenCookieModal(index)}
                                        className="c-item-02"
                                        target={index === 0 ? '_blank' : ''}
                                        href={
                                            index === 0 ? item?.value : '#!'
                                        }
                                        rel="noreferrer">
                                        {item?.title}
                                    </a>
                                }
                            </div>

                        ))
                    }

                </div>


            </div>
        </footer>
    );
};

export default Footer;
