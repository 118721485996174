import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import React from 'react';

import { put } from '../../core/client';
import { useAppSelector } from '../../store';
import { useDispatch } from 'react-redux';
import { getUserInfo } from '../../store/features/user/usersSlice';

const KvkkModal = ({ setOpenKvkkModal, openKvkkModal }: any) => {
    const { Kvkk }: any = useAppSelector(state => state.users);
    const { words } = useAppSelector(state => state.localization);
    const dispatch = useDispatch();

    const KvkkAccept = async () => {
        const endpoint = `/kvkk/approve-kvkk`;
        await put<any>(endpoint, '', '');
        setOpenKvkkModal(false);
        dispatch(getUserInfo())
    };
    const handleClose = () => {
        setOpenKvkkModal(false);
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modal-04"
            open={openKvkkModal &&openKvkkModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={openKvkkModal}>
                <>
                    <div className="modal-content-04">
                        <a  className="close-icon-kvkk" onClick={handleClose}>
                            <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M17.4882 18.8672L32.8551 34.2341L34.9764 32.1128L19.6096 16.7459L34.2341 2.12138L32.1128 5.7195e-05L17.4882 14.6246L2.86366 0L0.742336 2.12132L15.3669 16.7459L0 32.1128L2.12132 34.2341L17.4882 18.8672Z"
                                    fill="black"
                                />
                            </svg>
                        </a>
                        <h1 className="c-item-01">{words && words['WEB.KVKK']}</h1>
                        <div dangerouslySetInnerHTML={{ __html: Kvkk.items?.text }} className="c-item-02"></div>
                        <a  onClick={KvkkAccept} className="c-item-03">
                            {words && words['WEB.accept']}
                        </a>
                    </div>
                </>
            </Fade>
        </Modal>
    );
};

export default KvkkModal;
