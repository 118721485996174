import { ClickAwayListener, Grow, MenuList, Paper } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from '@material-ui/core/Drawer';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import React, { useEffect, useState, useRef } from 'react';
import {
    mobileVendor,
    browserVersion,
    browserName as browserNames,
    isAndroid,
    isIOS,
    mobileModel,
    isMobileOnly,
    osName,
    osVersion,
    isMacOs,
} from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import 'swiper/modules/free-mode/free-mode.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

import gift from '../../../_assets/gift/gif1.gif';
import { ReactComponent as HeaderResponsiveLogo } from '../../../_assets/img/İK_header_logo.svg';
import { analiticsLog, isBrave, scrollToTop, userId } from '../../../_helpers/useful-functions';
import { post } from '../../../core/client';
import { getLocalStorageItem, setLocalStorageItem } from '../../../core/utility';
import { IApplicationFormData } from '../../../models/components/layouts/layout1/header';
import AppCard from '../../../pages/home/AppCard';
import { useAppSelector } from '../../../store';
import {
    getTabBarApplicationList,
    setSlideItemIndex,
    setSlideItem,
    setAppCardToClose,
    setAppIndex,
    setDrawerMenuOpen,
} from '../../../store/features/hr/hrSlice';
import { getApplicationDetailsListForDetails, getUrlName } from '../../../store/features/hrDetail/hrDetailsSlice';
import { getLocalizationWords } from '../../../store/features/localization/localizationSlice';
import Spinner from '../../ui/spinner';
import SearchModal from './SearchModal';

declare global {
    interface Window {
        dataLayer: any;
    }
}

let sliderTimeout: any = null;

const Header = (props: any) => {
    const culture = getLocalStorageItem('SelectedLanguage');
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [openPreloginMenu, setOpenPreloginMenu] = useState(false);
    const anchorButtonRef = useRef<HTMLButtonElement>(null);
    const { userInfo, userLoading } = useAppSelector(state => state.users.info);
    const { loginId } = useAppSelector(state => state.users.globalLogin);
    const { words, languageInitialData } = useAppSelector(state => state.localization);
    const [userName, setUserName] = useState<string>('');
    const [language, setLanguage] = useState<string>(culture === 'tr-TR' || !culture ? 'TR' : 'EN');
    const [searchOpenModal, setSearchOpenModal] = useState<boolean>(false);
    const [isChangedLanguage, setIsChangedLanguage] = useState<boolean>(false);
    const { urlName }: any = useAppSelector(state => state.hrDetails);
    const [urlId, setUrlId] = useState("")
    const [newsUrlId, setNewsUrlId] = useState("")

    const { tabBarApplications, slideItemIndex, slideItem, appCardToOpen } = useAppSelector(state => state.hr);
    const { loading } = useAppSelector(state => state.hrDetails.applicationDetailsList);
    const [swiper, setSwiper] = useState(null);
    const history = useHistory();
    const dispatch = useDispatch();

    const openCookieModal = getLocalStorageItem('OpenCookiesModal');

    const handleDrawerOpenAndClose = () => {
        dispatch(setAppCardToClose(!appCardToOpen));
        dispatch(setSlideItem(0));
        dispatch(setAppIndex(1));
        dispatch(setSlideItemIndex({ slideIndex: 0 }));
    };
    const handleCloseLanguageMenu = data => {
        setOpen(false);
        if (history?.location?.pathname?.includes('icerikDetay') || history?.location?.pathname?.includes('cardDetail')) {
            scrollToTop();
        }
        setLocalStorageItem('SelectedLanguage', data?.culture);
        setIsChangedLanguage(data?.culture !== culture);
    };

    const handleClose = cultureData => {
        if (history?.location?.pathname?.includes('icerikDetay') || history?.location?.pathname?.includes('cardDetail')) {
            scrollToTop();
        }
        setLocalStorageItem('SelectedLanguage', cultureData === 'TR' ? 'tr-TR' : 'en-US');
        setLanguage(cultureData);
        setIsChangedLanguage(cultureData !== language);
        setOpenPreloginMenu(false);
    };

    useEffect(() => {
        dispatch(getUrlName());
    }, [])

    useEffect(() => {
        setUrlId(urlName?.items?.find(urlItem => urlItem.url === props?.match?.params?.id)?.id)
        setNewsUrlId(urlName?.items?.find(urlItem => urlItem.url === props?.match?.params?.newId)?.id)
    }, [urlName])


    useEffect(() => {
        if (isChangedLanguage) {
            dispatch(getLocalizationWords());
            dispatch(getUrlName());
            setLanguage(culture === 'tr-TR' ? 'TR' : 'EN');
        }
        //!dile göre url  değiştirmek için..
        if (culture) {
            if (history?.location?.pathname?.includes('Haberler') && culture === 'en-US') {
                history.push('/News');
            } else if (history?.location?.pathname?.includes('News') && culture === 'tr-TR') {
                history.push('/Haberler');
            } else if (history?.location?.pathname?.includes('cerez') && culture === 'en-US') {
                history.push('/cookie');
            } else if (history?.location?.pathname?.includes('cookie') && culture === 'tr-TR') {
                history.push('/cerez');
            } else if (history?.location?.pathname?.includes('kvkk') && culture === 'en-US') {
                history.push('/legal-informations');
            } else if (history?.location?.pathname?.includes('legal-informations') && culture === 'tr-TR') {
                history.push('/kvkk');
            } else if (history?.location?.pathname?.includes('toBeKoc') && culture === 'tr-TR') {
                history.push('/kocluOlmak');
            } else if (history?.location?.pathname?.includes('kocluOlmak') && culture === 'en-US') {
                history.push('/toBeKoc');
            } else if (history?.location?.pathname?.includes('grouplessons') && culture === 'tr-TR') {
                history.push('/ozelders');
            } else if (history?.location?.pathname?.includes('ozelders') && culture === 'en-US') {
                history.push('/grouplessons');
            }
        }
        setIsChangedLanguage(language !== culture);
        //!dile seçiminde tabbardan tabbar için istek atmak
        if (appCardToOpen && tabBarApplications?.items?.length > 0) {
            dispatch(getTabBarApplicationList());
            dispatch(getApplicationDetailsListForDetails());
        }
    }, [culture]);


    useEffect(() => {
        if (!urlName?.loading) {
            if (history?.location?.pathname?.includes('uygulamaDetay') && culture === 'en-US') {
                history.push(`/appDetail/${urlName?.items?.find(urlItem => urlItem.id === urlId)?.url}`);
            } else if (history?.location?.pathname?.includes('appDetail') && culture === 'tr-TR') {
                history.push(`/uygulamaDetay/${urlName?.items?.find(urlItem => urlItem.id === urlId)?.url}`);
            } else if (history?.location?.pathname?.includes('icerikDetay') && culture === 'en-US') {
                history.push(`/cardDetail/${urlName?.items?.find(urlItem => urlItem.id === urlId)?.url}`);
            } else if (history?.location?.pathname?.includes('cardDetail') && culture === 'tr-TR') {
                history.push(`/icerikDetay/${urlName?.items?.find(urlItem => urlItem.id === urlId)?.url}`);
            } else if (history?.location?.pathname?.includes('haberDetay') && culture === 'en-US') {
                history.push(`/newsDetail/${urlName?.items?.find(urlItem => urlItem.id === newsUrlId)?.url}`);
            } else if (history?.location?.pathname?.includes('newsDetail') && culture === 'tr-TR') {
                history.push(`/haberDetay/${urlName?.items?.find(urlItem => urlItem.id === newsUrlId)?.url}`);
            }
        }
    }, [urlName?.loading])

    useEffect(() => {
        if (userInfo?.name) {
            if (userInfo?.name.length > 15) {
                const name = userInfo?.name?.split(' ');
                setUserName(name[0]);
            } else {
                setUserName(userInfo?.name);
            }
        }
    }, [userInfo?.name]);

    useEffect(() => {
        const kvkkElement = document?.getElementById('kvkk-wrapper');
        const headerWrapperElement = document?.getElementById('header-wrapper');
        if (kvkkElement) {
            headerWrapperElement.classList?.add('loginUser');
        }
    }, []);

    const clickOpenMenu = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const clickPreloginOpenMenu = () => {
        setOpenPreloginMenu(prevOpen => !prevOpen);
    };

    const openAsideMenu = () => {
        dispatch(setDrawerMenuOpen(true));
    };

    const onScrolling = () => {
        const kvkkElement = document?.getElementById('kvkk-wrapper');
        const headerWrapperElement = document?.getElementById('header-wrapper');
        const header = document?.getElementById('header-01');
        const newsElement = document?.getElementById('the-news');
        const informationContentElement = document?.getElementById('information-content');
        const heroSliderElement = document?.getElementById('hero-slider');
        const newsDetailElement = document?.getElementById('the-news-details');
        const newsElementTop = newsElement?.getBoundingClientRect().top;
        const heroSliderElementTop = heroSliderElement?.getBoundingClientRect().top;
        const informationContentElementTop = informationContentElement?.getBoundingClientRect()?.top ?? -105;
        const newsDetailElementTop = newsDetailElement?.getBoundingClientRect().top;

        if (kvkkElement) {
            headerWrapperElement.classList?.remove('noScroll');
            headerWrapperElement.classList?.add('loginUser');
        }
        if (!userId) {
            if (heroSliderElementTop < -70 || informationContentElementTop < -200 || newsElementTop < -50 || newsDetailElementTop < -150) {
                headerWrapperElement.classList.add('scrollingTime');
                header.classList.remove('responsive');
                headerWrapperElement.classList?.remove('noScroll');
                headerWrapperElement.classList?.remove('loginUser');
            } else if (
                (heroSliderElementTop >= -70 || informationContentElementTop >= -200 || newsElementTop >= -50 || newsDetailElementTop >= -150) &&
                !headerWrapperElement.classList?.contains('noScroll')
            ) {
                headerWrapperElement.classList.add('noScroll');
                headerWrapperElement.classList?.remove('scrollingTime');
                header.classList?.add('responsive');
                headerWrapperElement.classList?.remove('loginUser');
            }
        } else if (userId && !headerWrapperElement?.classList?.contains('loginUser')) {
            headerWrapperElement?.classList.add('loginUser');
            headerWrapperElement?.classList?.remove('scrollingTime');
            headerWrapperElement?.classList?.remove('noScroll');
        }
    };

    useEffect(() => {
        document?.getElementById('root').addEventListener('scroll', onScrolling);

        return () => {
            document?.getElementById('root').removeEventListener('scroll', onScrolling);
        };
    }, [userId]);

    ///swiper func.
    useEffect(() => {
        if (appCardToOpen) {
            dispatch(getTabBarApplicationList());
            dispatch(getApplicationDetailsListForDetails());
        }
    }, [appCardToOpen]);

    useEffect(() => {
        if (swiper) {
            swiper.slideTo(slideItem, 0, true);
        }
    }, [slideItem]);

    useEffect(() => {
        const interval = setInterval(() => {
            const yy_ = slideItemIndex?.index + 1;

            if (appCardToOpen) {
                if (tabBarApplications?.items?.length === yy_) {
                    dispatch(setSlideItemIndex({ slideIndex: 0 }));
                    dispatch(setSlideItem(0));
                    swiper?.slideTo(slideItem, 0, true);
                } else {
                    dispatch(setSlideItemIndex({ slideIndex: slideItemIndex?.index + 1 }));
                    dispatch(setSlideItem(slideItemIndex?.index + 1));
                    swiper?.slideTo(slideItem, 0, true);
                }
            } else {
                dispatch(setSlideItemIndex({ slideIndex: 0 }));
                dispatch(setSlideItem(0));
                swiper?.slideTo(slideItem, 0, true);
            }
        }, 7000);

        return () => {
            clearInterval(interval);
        };
    }, [slideItemIndex?.index, slideItem, appCardToOpen]);

    const handleOnMouseOver = (slideIndex_: number) => {
        if (sliderTimeout !== null) {
            clearTimeout(sliderTimeout);
        }
        sliderTimeout = setTimeout(function () {
            if (slideIndex_ !== slideItemIndex?.index) {
                if (swiper) {
                    dispatch(setSlideItemIndex({ slideIndex: slideIndex_ }));
                }
            }
        }, 500);
    };

    const handleNextClick = () => {
        if (swiper) {
            const yy = tabBarApplications?.items?.length - 1;
            if (slideItemIndex?.index === yy) {
                dispatch(setSlideItem(0));
                dispatch(setSlideItemIndex({ slideIndex: 0 }));
                swiper?.slideTo(slideItem, 0, true);
            } else {
                dispatch(setSlideItem(slideItemIndex?.index + 1));
                dispatch(setSlideItemIndex({ slideIndex: slideItemIndex?.index + 1 }));
                swiper?.slideTo(slideItem, 0, true);
            }
        }
    };
    const handlePrevClick = () => {
        if (swiper) {
            if (slideItemIndex?.index === 0) {
                const xx_ = tabBarApplications?.items?.length - 1;
                dispatch(setSlideItemIndex({ slideIndex: xx_ }));
                dispatch(setSlideItem(xx_));
                swiper?.slideTo(slideItem, 0, true);
            } else {
                const xx = slideItemIndex?.index - 1;
                dispatch(setSlideItemIndex({ slideIndex: xx }));
                dispatch(setSlideItem(xx));
                swiper?.slideTo(slideItem, 0, true);
            }
        }
    };

    const handleClickLogo = () => {
        dispatch(setAppCardToClose(false));
        scrollToTop();
    };

    const openSearchModal = () => {
        setSearchOpenModal(true);
    };

    const handleMouseOut = () => {
        clearTimeout(sliderTimeout);
    };

    const handleMenuClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    const handlePreloginMenuClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorButtonRef.current && anchorButtonRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpenPreloginMenu(false);
    };



    const handleApplicationLogin = async applicationName => {
        const email = getLocalStorageItem('USER_EMAIL');
        const os = isIOS ? 1 : isAndroid ? 2 : 0; //! IOS=1, Android=2, Huawei=3
        const deviceName = isMobileOnly ? mobileVendor : null;
        const deviceModel = isMobileOnly ? mobileModel : null;
        const isMobile = isMobileOnly;
        const osNameWeb = osName;
        const osVersionWeb = osVersion;
        const browserName = isBrave() ? 'Brave' : browserNames;

        const applicationFormData: IApplicationFormData = {
            email,
            applicationName,
            loginId,
            isMobile,
            os,
            deviceName,
            deviceModel,
            osVersion: null,
            version: null,
            deviceId: null,
            schema: null,
            //!add pls
            browserName,
            browserVersion,
            osNameWeb,
            osVersionWeb,
            //!
        };
        const endpoint = `/hr/click-application-link`;
        await post<any>(endpoint, applicationFormData, '');
    };

    const handleClickApplication = applicationName => {
        analiticsLog(applicationName);
        handleApplicationLogin(applicationName);
    };

    return userInfo?.length === 0 ? (
        <Spinner />
    ) : (
        <div className={isMacOs ? 'comp-header-01 isMacOs responsive' : 'comp-header-01 responsive'} id="header-01">
            <div className={userId ? 'c-wrapper active' : 'c-wrapper'} id="header-wrapper">
                <div className="c-item-mobil-responsive">
                    <button onClick={openAsideMenu} className="c-container-04">
                        <svg width="26" height="18" viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="26" height="2" fill="white" />
                            <rect width="26" height="2" transform="matrix(1 0 0 -1 0 10)" fill="white" />
                            <rect y="16" width="26" height="2" fill="white" />
                        </svg>
                    </button>
                    <Link to="/" onClick={handleClickLogo} className="c-container-01">
                        <HeaderResponsiveLogo className="c-item-mobil-logo" />
                    </Link>
                </div>
                <Link to="/" onClick={handleClickLogo} className="c-container-01">
                    <HeaderResponsiveLogo className="c-item-01" />
                </Link>

                <div className={userId ? 'c-container-02' : 'c-container-02 no-entry'}>
                    {userId && (
                        <>
                            <button className="c-item" onClick={handleDrawerOpenAndClose}>
                                <span className="c-item-01">{words && words['WEB.apps']}</span>

                                {appCardToOpen ? (
                                    <svg
                                        className="c-item-02 f-close-x"
                                        width="15"
                                        height="15"
                                        viewBox="0 0 54 52"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M26.8701 26.8701L51.6188 51.6188L53.7401 49.4975L28.9914 24.7488L51.6188 2.12141L49.4975 8.47578e-05L26.8701 22.6275L4.24264 0L2.12132 2.12132L24.7488 24.7488L0 49.4976L2.12132 51.6189L26.8701 26.8701Z"
                                            fill="white"
                                        />
                                    </svg>
                                ) : (
                                    <img className="c-item-02" src={gift} alt="gift" />
                                )}
                            </button>

                            {!tabBarApplications?.loading && !loading && (
                                <Drawer
                                    className={isMacOs ? 'drawer-02 isMacOs' : 'drawer-02'}
                                    variant="persistent"
                                    anchor="top"
                                    open={appCardToOpen}>
                                    <div className="comp-carousel-04">
                                        <div className="c-wrapper">
                                            {tabBarApplications?.loading ? (
                                                <Spinner />
                                            ) : (
                                                <>
                                                    <>
                                                        <div className="c-swiper-arrows">
                                                            <button className="swiper-prev-button" onClick={handlePrevClick}>
                                                                <svg
                                                                    width="13"
                                                                    height="21"
                                                                    viewBox="0 0 13 21"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M12 1L2 10.5L12 20"
                                                                        stroke="white"
                                                                        strokeWidth="1.5"
                                                                        strokeLinecap="round"
                                                                    />
                                                                </svg>
                                                            </button>
                                                            <button className="swiper-next-button" onClick={handleNextClick}>
                                                                <svg
                                                                    width="13"
                                                                    height="21"
                                                                    viewBox="0 0 13 21"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M0.999998 20L11 10.5L1 0.999999"
                                                                        stroke="white"
                                                                        strokeWidth="1.5"
                                                                        strokeLinecap="round"
                                                                    />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                        <Swiper
                                                            onSwiper={e => {
                                                                setSwiper(e);
                                                            }}
                                                            lazy
                                                            // draggable
                                                            // scrollbar={{ el: '.swiper-scrollbar', hide: false, draggable: true }}
                                                            slidesPerView={8}
                                                            loop
                                                            spaceBetween={10}
                                                            slideToClickedSlide
                                                            // loopedSlides={12}
                                                            loopedSlides={tabBarApplications?.items?.length}
                                                            // autoplay={{
                                                            //     delay: 20000,
                                                            //     disableOnInteraction: false,
                                                            //     stopOnLastSlide: false,
                                                            // }}
                                                            allowTouchMove={false}
                                                            navigation={{ nextEl: 'swiper-next-button', prevEl: 'swiper-prev-button' }}>
                                                            {tabBarApplications?.items?.length > 0 &&
                                                                tabBarApplications?.items?.map?.((item: any, index: number) => (
                                                                    <SwiperSlide
                                                                        key={'application-' + item?.id}
                                                                    >
                                                                        <a
                                                                            onClick={() => handleClickApplication(item?.header)}
                                                                            onMouseOver={() => handleOnMouseOver(index)}
                                                                            onMouseOut={() => handleMouseOut()}
                                                                            href={
                                                                                item?.url?.includes('kocailem.com')
                                                                                    ? item?.baseUrl + '/?loginId=' + loginId
                                                                                    : item?.url
                                                                            }
                                                                            className="c-item"
                                                                            target="_blank"
                                                                            rel="noreferrer">
                                                                            {/* <img className="c-item-01" src={icon} /> */}
                                                                            <img
                                                                                loading="lazy"
                                                                                className="c-item-01"
                                                                                src={index === slideItemIndex?.index ? item?.iconUrl2 : item?.iconUrl}
                                                                            />
                                                                            <h6 className="c-item-02">{item?.normalizedHeader}</h6>
                                                                        </a>
                                                                    </SwiperSlide>
                                                                ))}
                                                        </Swiper>
                                                    </>

                                                    <AppCard />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </Drawer>
                            )}

                            <button ref={anchorRef} onClick={clickOpenMenu} className={open ? 'c-item-03 active-open-menu' : 'c-item-03'}>
                                {userLoading ? (
                                    <CircularProgress size={30} />
                                ) : (
                                    <>
                                        <div className="c-item-03-A">
                                            <img
                                                src={
                                                    userInfo?.photo
                                                        ? userInfo?.photo
                                                        : 'https://khicindekocvar.blob.core.windows.net/prod/koc_icon_user.svg'
                                                }
                                                alt="avatar"
                                                className="c-unit-01"
                                            />
                                            <div className="c-unit-02">
                                                <h6 className={open ? 'c-unit-02-A active' : 'c-unit-02-A'}>{words && words['WEB.hello']} </h6>
                                                <h6 className={open ? 'c-unit-02-B active-name' : 'c-unit-02-B'}>{userName || ''}</h6>
                                            </div>
                                        </div>

                                        {open ? (
                                            <svg
                                                className="c-item-03-B"
                                                width="14"
                                                height="8"
                                                viewBox="0 0 14 8"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13 7L7 0.999999L1 7" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        ) : (
                                            <svg
                                                className="c-item-03-B"
                                                width="14"
                                                height="8"
                                                viewBox="0 0 14 8"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 1L7 7L13 1" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        )}
                                    </>
                                )}
                            </button>
                            <Popper className="simple-menu" open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                                {({ TransitionProps, placement }) => (
                                    <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                                        <Paper>
                                            <ClickAwayListener onClickAway={handleMenuClose}>
                                                <MenuItem onClick={handleMenuClose}>
                                                    <div className="c-item-02">
                                                        <Link to="/profile" onClick={() => scrollToTop()} className="c-item-02-group">
                                                            <span className="c-item-02-A">{userInfo?.email || ''}</span>
                                                            <span className="c-item-02-B">{words && words['WEB.myProfile']}</span>
                                                        </Link>
                                                        <div className="c-item-02-C">
                                                            <span className="c-unit-01">{words && words['WEB.language']}</span>
                                                            <div className="c-unit-02">
                                                                {languageInitialData.map((data, index) => (
                                                                    <button
                                                                        onClick={() => handleCloseLanguageMenu(data)}
                                                                        key={'language' + index}
                                                                        className={data?.culture === culture ? 'c-unit-02-A active' : 'c-unit-02-A'}>
                                                                        {data.cultureName}
                                                                    </button>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        <a
                                                            onClick={async (e: any) => {
                                                                window.localStorage.clear();
                                                                scrollToTop();
                                                                setLocalStorageItem('SelectedLanguage', culture);
                                                                setLocalStorageItem('OpenCookiesModal', openCookieModal);
                                                            }}
                                                            href="/"
                                                            className="c-item-02-D">
                                                            {words && words['WEB.exit']}
                                                        </a>
                                                    </div>
                                                </MenuItem>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </>
                    )}
                    {!userId && (
                        <a
                            className="c-item-04"
                            href="#!"
                            onClick={async (e: any) => {
                                e.preventDefault();
                                window.localStorage.clear();
                                setLocalStorageItem('SelectedLanguage', culture);
                                history.push('/accounts/login');
                                setLocalStorageItem('isLogin', true);
                                setLocalStorageItem('OpenCookiesModal', openCookieModal);
                            }}>
                            {words && words['WEB.LOGIN']}
                        </a>
                    )}
                    {!userId && (
                        <a
                            className="c-item-responsive"
                            href="#!"
                            onClick={async (e: any) => {
                                setLocalStorageItem('OpenCookiesModal', openCookieModal);
                                e.preventDefault();
                                window.localStorage.clear();
                                setLocalStorageItem('SelectedLanguage', culture);
                                history.push('/mobileStore');
                            }}>
                            {words && words['WEB.LOGIN']}
                        </a>
                    )}

                    <SearchModal searchOpenModal={searchOpenModal} setSearchOpenModal={setSearchOpenModal} />
                    <button className="search-button mr-30" onClick={() => openSearchModal()}>
                        <svg className="search-icon" width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path d="M23 23L18 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                    {!userId && (
                        <>
                            <button ref={anchorButtonRef} onClick={clickPreloginOpenMenu} className="c-item-05">
                                <span className="c-item-05-A">{language}</span>
                                <svg className="c-item-05-B" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1L6.5 7L12 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </button>

                            <Popper
                                className="language-menu"
                                open={openPreloginMenu}
                                anchorEl={anchorButtonRef.current}
                                role={undefined}
                                transition
                                disablePortal>
                                {({ TransitionProps, placement }) => (
                                    <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                                        <Paper>
                                            <ClickAwayListener onClickAway={handlePreloginMenuClose}>
                                                <MenuList>
                                                    <MenuItem onClick={() => handleClose('TR')}>
                                                        <span className={language === 'TR' ? 'language active' : 'language'}>TR</span>
                                                    </MenuItem>
                                                    <MenuItem onClick={() => handleClose('EN')}>
                                                        <span className={language === 'EN' ? 'language active' : 'language'}>EN</span>
                                                    </MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </>
                    )}
                </div>
            </div>

            <div className="c-container-03"></div>
        </div>
    );
};

export default Header;
