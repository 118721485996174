import React, { useState, useEffect, FC } from 'react';
import { isMacOs } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { scrollToTop } from '../../_helpers/useful-functions';
import { IAppDetailCards } from '../../models/components/details/AppDetailCards';
import { useAppSelector } from '../../store';
import { setAppCardDetailToOpen } from '../../store/features/hr/hrSlice';
import { getCardDetail, setAppCardDetailIndex } from '../../store/features/hrDetail/hrDetailsSlice';

const AppDetailCards: FC<IAppDetailCards> = ({ cards }) => {
    const [appCardOnHover, setAppCardOnHover] = useState(0);
    const [cardId, setCardId] = useState(null);
    const { words } = useAppSelector(state => state.localization);
    const { urlName }: any = useAppSelector(state => state.hrDetails);

    const dispatch = useDispatch();

    const handleMouseOver = (index: number) => {
        setAppCardOnHover(index);
    };

    useEffect(() => {
        if (cardId) {
            dispatch(getCardDetail(cardId));
        }
    }, [cardId]);

    const handleOpenToDetailSlider = (index: number, cardId) => {
        setCardId(cardId);
        dispatch(setAppCardDetailIndex(index));
        dispatch(setAppCardDetailToOpen(true));
        scrollToTop();
    };

    return (
        <div className={isMacOs ? 'section-05 isMacOs' : 'section-05'} id="app-detail-cards">
            <div className="c-wrapper">
                <div className="c-container">
                    {cards?.map((item, index) => {
                        return (
                            <Link
                                key={'ikCatiLeftMenuWidgets' + index}
                                to={{ pathname: `/${words && words['WEB.cardDetails']}/${urlName?.items?.find(urlItem => urlItem.id === item?.id)?.url}` }}
                                onClick={() => handleOpenToDetailSlider(index + 1, item.id)}
                                className={appCardOnHover === index + 1 ? 'c-item active' : 'c-item'}
                                onMouseOver={() => handleMouseOver(index + 1)}>
                                {item?.image.endsWith('.svg') ||
                                item?.image.endsWith('.png') ||
                                item?.image.endsWith('.jpeg') ||
                                item?.image.endsWith('.jpg') ? (
                                    <img
                                        loading="lazy"
                                        className={appCardOnHover === index + 1 ? 'c-item-01 filter' : 'c-item-01'}
                                        src={item?.image}
                                    />
                                ) : (
                                    <video className="c-item-01" controls={false} autoPlay={false}>
                                        <source src={item?.image} type="video/mp4" />
                                    </video>
                                )}
                                {appCardOnHover === index + 1 && (
                                    <div className="c-item-02">
                                        <h6 className="c-unit-02">{item?.name}</h6>
                                        <p className="c-unit-03">{item?.title}</p>
                                    </div>
                                )}
                            </Link>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default AppDetailCards;
