import React, { useEffect } from 'react';

const WeatherWidget = () => {
    const weatherWidgetContainer = document?.getElementById('weather-widget');
    const widgetHTML = `<div id="ww_425be0100dd46" v='1.3' loc='auto' a='{"t":"horizontal","lang":"tr","sl_lpl":1,"ids":[],"font":"Roboto","sl_ics":"one","sl_sot":"celsius","cl_bkg":"image","cl_font":"#FFFFFF","cl_cloud":"#FFFFFF","cl_persp":"#81D4FA","cl_sun":"#FFC107","cl_moon":"#FFC107","cl_thund":"#FF5722","el_nme":3,"el_phw":3,"el_whr":3}'><a href="https://weatherwidget.org/tr/" id="ww_425be0100dd46_u" target="_blank">Web Sitesi için Ücretsiz HTML Hava Durumu Widget</a></div>`;

    const elemId = 'ww_425be0100dd46';
    const weatherElement = document.getElementById(elemId);
    const elemLocAttribute = weatherElement?.getAttribute('loc');

    if (weatherWidgetContainer && !weatherElement) {
        weatherWidgetContainer.innerHTML = widgetHTML;
    }

    useEffect(() => {
        if (elemLocAttribute) {
            const script = document.createElement('script');
            script.dataset.name = 'weather-widget-script';
            script.src = `https://app1.weatherwidget.org/js/?id=${elemId}`;
            script.async = true;
            script.defer = true;
            document?.head?.appendChild?.(script);
        }
        
        //!delete script more than one.
        const allWeatherWidget = document.querySelectorAll('[data-name]');
        if (allWeatherWidget.length > 1) {
            for (let i = 0; i < allWeatherWidget.length - 1; i++) {
                const weatherWidgetElem = allWeatherWidget[i];
                weatherWidgetElem?.remove();
            }
        }
    }, [elemLocAttribute]);

    return (
        <div className="c-item-weather-condition">
            <div id="weather-widget"></div>
        </div>
    );
};

export default WeatherWidget;
