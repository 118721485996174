import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import AppDetailCards from '../../components/details/AppDetailCards';
import AppDetailCardsResponsive from '../../components/details/AppDetailCardsResponsive';
import ContinueDiscover from '../../components/details/ContinueDiscover';
import ContinueDiscoverResponsive from '../../components/details/ContinueDiscoverResponsive';
import InformationContent from '../../components/details/InformationContent';
import { getLocalStorageItem } from '../../core/utility';
import { useAppSelector } from '../../store';
import { getLeftMenuList, getLeftMenuListForDetail, getUrlName } from '../../store/features/hrDetail/hrDetailsSlice';

const AppDetail = (props: any) => {
    const dispatch = useDispatch();
    const culture = getLocalStorageItem('SelectedLanguage');

    const { leftMenuDetailList, leftMenuList, urlName }: any = useAppSelector(state => state.hrDetails);

    useEffect(() => {
        dispatch(getLeftMenuList());
        dispatch(getUrlName());
    }, [culture]);

    useEffect(() => {
        dispatch(getUrlName());
    }, [])
    

    useEffect(() => {
        if (!props?.match?.params?.id) return;
        if (!leftMenuList?.loading && props?.match?.params?.id) {
            const pageId = urlName?.items?.find(urlItem => urlItem.url === props?.match?.params?.id)?.id
            dispatch(getLeftMenuListForDetail(pageId));
        }
    }, [props?.match?.params?.id, leftMenuList?.loading, culture, urlName]);

    return (
        <>
            {leftMenuDetailList?.loading ? (
                <div className="spinner-02">
                    <CircularProgress size={100} />
                </div>
            ) : (
                <>
                
                    <InformationContent data={leftMenuDetailList} />
                    <AppDetailCards cards={leftMenuDetailList?.items?.cards} />
                    <AppDetailCardsResponsive cards={leftMenuDetailList?.items?.cards}  />
                    <ContinueDiscover data={leftMenuDetailList?.items?.explorer} />
                    <ContinueDiscoverResponsive data={leftMenuDetailList?.items?.explorer} />
                </>
            )}
        </>
    );
};

export default AppDetail;
