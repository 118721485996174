import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { scrollToTop } from '../../_helpers/useful-functions';
import { useHistory } from 'react-router-dom';
import { get } from '../../core/client';
import { getLocalStorageItem, setLocalStorageItem } from '../../core/utility';
import { getLeftMenuList } from '../../store/features/hrDetail/hrDetailsSlice';
import { isMacOs } from 'react-device-detect';

const PrivateCourse = () => {
  const [privateCourseData, setPrivateCourseData] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const culture = getLocalStorageItem('SelectedLanguage');
  const PATHNAME_IS_EN = history?.location?.pathname?.includes('grouplessons');
  
  useEffect(() => {
    setLocalStorageItem('SelectedLanguage', PATHNAME_IS_EN ? 'en-US' : 'tr-TR');
  }, [])

  useEffect(() => {
    scrollToTop();
    dispatch(getLeftMenuList());
    getNewsDetail(culture === 'en-US');
  }, [culture]); 
  
  const getNewsDetail = async (isEn) => {
    await get<any>(`hr/get-detail-left-menu?name=${isEn ? 'grouplessons' : 'ozelders'}`).then(res => {
      if (res.isSuccessful) {
        setPrivateCourseData(res?.result?.item);
      }
    });
  };

  return (
    <>
      <div className={isMacOs ? "news-detail-01 isMacOs" : "news-detail-01"} id="the-news-details">
        <div className="c-wrapper">
          <img loading="lazy" className="c-item-01" src={privateCourseData?.image} />
          <img loading="lazy" className="c-item-01-responsive" src={privateCourseData?.image} />
          <div className="c-item">
            <div className="c-item-02">
              <h6 className="c-unit-01">{privateCourseData?.title}</h6>
            </div>
            <div className="c-item-03" dangerouslySetInnerHTML={{ __html: privateCourseData?.body }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivateCourse;
