import React, { useState, useEffect } from 'react';
import { isIOS, isAndroid, isSafari, isChrome, isFirefox, isEdge, isMobileOnly, isMacOs } from 'react-device-detect';
import LazyLoad from 'react-lazy-load';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Pagination, Mousewheel, Keyboard, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { userId, isBrave } from '../../_helpers/useful-functions';
import { useAppSelector } from '../../store';
import { setAppCardDetailToOpen, setAppIndex } from '../../store/features/hr/hrSlice';

const HeroSlider = (props: any) => {
    const [activeSlide, setActiveSlide] = useState<number>(1);
    const [windowSize, setWindowSize] = useState({ width: undefined });
    const [swiper, setSwiper] = useState(null);
    const [responsiveFix, setResponsiveFix] = useState(null);
    const { banner } = useAppSelector(state => state.hr);
    const { words } = useAppSelector(state => state.localization);
    const { urlName }: any = useAppSelector(state => state.hrDetails);
    const dispatch = useDispatch();

    useEffect(() => {
        //! brave fix...
        const responsiveFixBrave = isBrave() && (isIOS || isAndroid) && !userId;
        //! - IOS : Safari - Chrome
        const responsiveFixIOSAndSafari = !userId && isIOS && isSafari;
        const responsiveFixIOSAndChrome = !userId && isIOS && isChrome;
        //! - Android : Chrome - Firefox - Edge
        const responsiveFixAndroidAndChrome = !userId && isAndroid && isChrome;
        const responsiveFixAndroidAndFirefox = !userId && isAndroid && isFirefox;
        const responsiveFixAndroidAndEdge = !userId && isAndroid && isEdge;

        if (responsiveFixBrave) {
            setResponsiveFix('f-fix-Brave');
        } else if (responsiveFixIOSAndSafari) {
            setResponsiveFix('f-fix-ios-safari');
        } else if (responsiveFixIOSAndChrome) {
            setResponsiveFix('f-fix-ios-chrome');
        } else if (responsiveFixAndroidAndChrome) {
            setResponsiveFix('f-fix-android-chrome');
        } else if (responsiveFixAndroidAndFirefox) {
            setResponsiveFix('f-fix-android-firefox');
            const progressbarEl = document.querySelector('.swiper-pagination-progressbar ');
            progressbarEl?.classList.add('f-fix-android-firefox');
        } else if (responsiveFixAndroidAndEdge) {
            setResponsiveFix('f-fix-android-edge');
        } else if (isAndroid && isMobileOnly) {
            setResponsiveFix('f-fix-android-firefox');
            const progressbarEl = document.querySelector('.swiper-pagination-progressbar ');
            progressbarEl?.classList?.add('f-fix-android-firefox');
        } else {
            setResponsiveFix(null);
        }
    }, [isIOS, isAndroid]);

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
            });
        }
        setWindowSize({ width: window.innerWidth });
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleNextClick = () => {
        if (activeSlide === banner?.items?.length) {
            swiper?.slideTo(0, 0, true);
            setActiveSlide(1);
        } else if (swiper) {
            swiper.slideNext();
        }
    };

    const handlePrevClick = () => {
        if (activeSlide === 1) {
            const lastSlide = banner?.items?.length - 1;
            swiper?.slideTo(lastSlide, 0, true);
            setActiveSlide(lastSlide + 1);
        } else if (swiper) {
            swiper.slidePrev();
        }
    };

    //! hero-slider pause - start begin..
    useEffect(() => {
        window.addEventListener('scroll', onScrolling);

        return () => {
            window.removeEventListener('scroll', onScrolling);
        };
    }, []);

    const onScrolling = () => {
        const sliderHero = document.querySelector('.comp-carousel-01') as HTMLElement;
        const activeEl = document.querySelector('.swiper-slide-active.hero-swiper-slide video') as HTMLVideoElement;
        const sliderHeroSliderTop = sliderHero?.getBoundingClientRect()?.top;

        if (sliderHero) {
            if (sliderHeroSliderTop < 150 && sliderHeroSliderTop > -850) {
                // swiper?.autoplay?.start();
                if (activeEl) {
                    activeEl.play();
                }
            } else {
                if (activeEl) {
                    activeEl.pause();
                }
            }
        }

        //! animasyondaki alttaki boşluğu kaldırmak için
        const isAnimationFinished = document.querySelector('.animation-main')?.classList?.contains('finished');

        if (isAnimationFinished) {
            sliderHero?.classList?.add('mt-300');
        } else {
            sliderHero?.classList?.remove('mt-300');
        }
    };
    //! hero-slider pause - start end..

    return (
        <div
            className={responsiveFix ? `comp-carousel-01 ${responsiveFix} mt-100` : !userId ? 'comp-carousel-01 mt-100' : 'comp-carousel-01'}
            id="hero-slider">
            <Swiper
                className={responsiveFix ? 'hero-swiper-slider f-fix-responsive' : 'hero-swiper-slider'}
                id="hero-swiper-slider"
                modules={[Pagination, Mousewheel, Keyboard, Autoplay]}
                pagination={{
                    type: 'progressbar',
                }}
                navigation={{ nextEl: 'button-next', prevEl: 'button-prev' }}
                onSlideChange={(e: any) => {
                    //play
                    const _nextEl = e.el.querySelector('.swiper-wrapper .swiper-slide:nth-child(' + (e.snapIndex + 1) + ') video');
                    if (_nextEl) {
                        _nextEl.currentTime = 0;
                        _nextEl.play();
                    }
                    const _activeEl = e.el.querySelector('.swiper-slide-active video');
                    if (_activeEl) {
                        _activeEl.pause();
                    }
                    setActiveSlide(e.realIndex + 1);
                }}
                onSwiper={e => {
                    setSwiper(e);
                }}>
                <div className="c-swiper-fragtion">{activeSlide < 10 ? '0' + activeSlide : activeSlide}</div>
                <div className="c-swiper-fragtion-responsive ">
                    {activeSlide < 10 ? '0' + activeSlide : activeSlide} / {'0' + banner?.items?.length}
                </div>
                <div className={responsiveFix === 'f-fix-android-firefox' ? 'c-swiper-arrows f-fix-firefox' : 'c-swiper-arrows'}>
                    <button className="c-item-prev button-prev" onClick={handlePrevClick}>
                        <svg width="13" height="21" viewBox="0 0 13 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 1L2 10.5L12 20" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                        </svg>
                    </button>
                    <button className="c-item-next button-next" onClick={handleNextClick}>
                        <svg width="13" height="21" viewBox="0 0 13 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.999998 20L11 10.5L1 0.999999" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                        </svg>
                    </button>
                </div>
                <div className={userId ? 'swiper-button-container active' : 'swiper-button-container f-no-user'}>
                    {banner?.items
                        ?.filter(item => item?.displayOrder !== activeSlide)
                        ?.map((item, index) => (
                            <div className={userId ? `c-item-01 active ${isMacOs ? "isMacOs" : ""}` : `c-item-01 ${isMacOs ? "isMacOs" : ""}`} key={'button-' + index}>
                                <a
                                    className="c-item-01-A"
                                    href="#!"
                                    onClick={() => {
                                        swiper?.slideTo(item?.displayOrder - 1, 300, true);
                                    }}>
                                    {item?.header}
                                </a>
                            </div>
                        ))}
                </div>

                {banner?.items?.length > 0 &&
                    banner?.items?.map((item, index) => (
                        <SwiperSlide key={item?.displayOrder} className="hero-swiper-slide">
                            <div className="c-container-01">
                                <Link
                                    onClick={() => {
                                        dispatch(setAppIndex(index + 1));
                                        dispatch(setAppCardDetailToOpen(false));
                                    }}
                                    target={item?.bannerTypeEnum === 2 ? '_blank' : ''}
                                    to={{
                                        pathname: item?.bannerTypeEnum === 0 ? `/${words && words['WEB.appDetails']}/${urlName?.items?.find(urlItem => urlItem.id === item?.id)?.url}` : item?.url,
                                    }}
                                    className="c-item">
                                    {item?.image.endsWith('.svg') ||
                                    item?.image.endsWith('.png') ||
                                    item?.image.endsWith('.jpeg') ||
                                    item?.image.endsWith('.jpg') ? (
                                        <img
                                            className={userId ? 'c-item-01 active' : 'c-item-01'}
                                            src={windowSize.width < 768 ? item?.mobileResponsiveImage : item?.image}
                                        />
                                    ) : userId ? (
                                        <LazyLoad>
                                            <video id="hero-slider-video-image" className="c-item-01 active" controls={false} autoPlay={false} playsInline>
                                                <source src={item?.image} type="video/mp4" />
                                            </video>
                                        </LazyLoad>
                                    ) : (
                                        <video
                                            playsInline
                                            id="hero-slider-video-image"
                                            className="c-item-01"
                                            controls={false}
                                            autoPlay={false}>
                                            <source src={item?.image} type="video/mp4" />
                                        </video>
                                    )}
                                    <div
                                        className={
                                            userId
                                                ? 'c-item-02 active'
                                                : responsiveFix === 'f-fix-android-firefox'
                                                ? 'c-item-02 f-fix-firefox'
                                                : 'c-item-02'
                                        }>
                                        {item?.firstHeader && <p className="c-unit-01">{item?.firstHeader}</p>}
                                        <p className="c-unit-02">{item?.header}</p>
                                        <div className="c-item-group">
                                            <div dangerouslySetInnerHTML={{ __html: item?.body }} className="c-unit-03"></div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </SwiperSlide>
                    ))}
            </Swiper>
        </div>
    );
};

export default HeroSlider;
