import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Navigation, Mousewheel, Keyboard, Autoplay, Pagination } from 'swiper';
import 'swiper/modules/autoplay/autoplay.scss';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useAppSelector } from '../../store';
import { userId } from '../../_helpers/useful-functions';

let sliderInterval: any = null;

const SliderNews = () => {
    const [activeSlide, setActiveSlide] = useState(1);
    const { news } = useAppSelector(state => state.hr);
    const { words } = useAppSelector(state => state.localization);
    const { urlName } = useAppSelector(state => state.hrDetails);
    const [swiperEl, setSwiperEl] = useState<any>(null);
    const [isPlay, setIsPlay] = useState<boolean>(false);

    const handleSwiper = (e: any) => {
        setSwiperEl(e);
        if (e) {
            e?.autoplay?.stop();
        }
    };
    const handleNextClick = () => {
        if (swiperEl) {
            swiperEl.slideNext();
        }
    };

    const handlePrevClick = () => {
        if (swiperEl) {
            swiperEl.slidePrev();
        }
    };

    //! slider-news pause - start begin..
    useEffect(() => {
        const scrollElement = userId ? window : document?.getElementById('root');
        scrollElement?.addEventListener('scroll', onScrolling);

        return () => {
            scrollElement?.removeEventListener('scroll', onScrolling);
        };
    }, [userId]);

    const onScrolling = e => {
        const sliderNews = document.querySelector('.comp-carousel-02');

        if (sliderNews) {
            const sliderNewsTop = sliderNews?.getBoundingClientRect()?.top;
            if (sliderNewsTop < 345 && sliderNewsTop > -300) {
                setIsPlay(true);
            } else if (sliderNewsTop >= 345 || sliderNewsTop <= -301) {
                setIsPlay(false);
            }
        }
    };

    useEffect(() => {
        if (isPlay) {
            sliderInterval = setInterval(() => {
                if (news?.items?.length === activeSlide) {
                    setActiveSlide(1);
                    swiperEl?.slideTo(0, 0, true);
                } else {
                    swiperEl?.slideNext();
                }
            }, 5000);
        }
        return () => {
            clearInterval(sliderInterval);
        };
    }, [isPlay, activeSlide]);
    //! slider-news pause - start end..
    return (
        <div className="comp-carousel-02">
            <div className="c-wrapper">
                <Swiper
                    pagination={{
                        type: 'progressbar',
                    }}
                    simulateTouch={false}
                    modules={[Navigation, Mousewheel, Keyboard, Autoplay, Pagination]}
                    loop
                    lazy
                    navigation={{ nextEl: 'button-next', prevEl: 'button-prev' }}
                    loopedSlides={news?.items?.length}
                    onSlideChange={(e: any) => {
                        setActiveSlide(e.realIndex + 1);
                    }}
                    onSwiper={(swiper_: any) => handleSwiper(swiper_)}>
                    <div className="c-swiper-arrows">
                        <button className="c-item-prev button-prev " onClick={handlePrevClick}>
                            <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 1L2 8.5L10 16" stroke="black" strokeWidth="2" strokeLinecap="round" />
                            </svg>
                        </button>
                        <button className="c-item-next button-next" onClick={handleNextClick}>
                            <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.999999 16L9 8.5L1 1" stroke="black" strokeWidth="2" strokeLinecap="round" />
                            </svg>
                        </button>
                    </div>

                    <div className="c-swiper-fragtion">
                        <span className="c-item-01">{activeSlide < 10 ? '0' + activeSlide : activeSlide}</span>
                        <span className="c-item-02">/</span>
                        {news?.items?.length < 10 ? '0' + (news?.items?.length || '') : news?.items?.length}
                    </div>

                    {news?.items?.length > 0 &&
                        news?.items?.map((item, index) => (
                            <SwiperSlide key={'news-' + index}>
                                <Link to={{ pathname: `/${words && words['WEB.newsDetails']}/${urlName?.items?.find(urlItem => urlItem.id === item?.id)?.url}` }} className="c-item">
                                    {item?.image.endsWith('.svg') ||
                                    item?.image.endsWith('.png') ||
                                    item?.image.endsWith('.jpeg') ||
                                    item?.image.endsWith('.jpg') ? (
                                    <>
                                        <img loading="lazy" className="c-item-01" src={item?.image} />
                                        <img loading="lazy" className="c-item-01-responsive" src={item?.imageSmall} />
                                    </>
                                    ) : (
                                        <video className="c-item-01" controls={false} autoPlay={false}>
                                            <source src={item?.image} type="video/mp4" />
                                        </video>
                                    )}
                                    <div className="c-item-02">
                                        <h6 className="c-item-02-A">{words && words['WEB.NEWS']}</h6>
                                        <h6 className="c-item-02-B">{item?.header}</h6>
                                    </div>
                                </Link>
                            </SwiperSlide>
                        ))}
                    <div className="c-swiper-responsive-bottom">
                        <div className="c-swiper-fragtion-responsive">
                            <span className="c-item-01">{activeSlide < 10 ? '0' + activeSlide : activeSlide}</span>
                            <span className="c-item-02">/</span>
                            {news?.items?.length < 10 ? '0' + (news?.items?.length || '') : news?.items?.length}
                        </div>
                        <div className="c-swiper-arrows-responsive">
                            <button className="c-item-prev button-prev " onClick={handlePrevClick}>
                                <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 1L2 8.5L10 16" stroke="black" strokeWidth="2" strokeLinecap="round" />
                                </svg>
                            </button>
                            <button className="c-item-next button-next" onClick={handleNextClick}>
                                <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.999999 16L9 8.5L1 1" stroke="black" strokeWidth="2" strokeLinecap="round" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </Swiper>
            </div>
        </div>
    );
};

export default SliderNews;
