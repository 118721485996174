import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useState } from 'react';
import { isMacOs } from 'react-device-detect';
import { Link } from 'react-router-dom';

import { get } from '../../core/client';
import { useAppSelector } from '../../store';
import NewsShareModal from './NewsShareModal';

const New = () => {
    const { news } = useAppSelector(state => state.hr);
    const { words }: any = useAppSelector(state => state.localization);
    const { urlName }: any = useAppSelector(state => state.hrDetails);

    const [newsOpenModal, setNewOpenModal] = useState(false);
    const [newsModalData, setNewModalData] = useState();

    const newDetailModal = async id => {
        await get<any>(`/hr/get-news-detail?id=` + id).then(res => setNewModalData(res?.result));

        setNewOpenModal(true);
    };

    const closeModal = () => {
        setNewOpenModal(false);
    };

    return (
        <div className={isMacOs ? 'news-01 isMacOs' : 'news-01'} id="the-news">
            <NewsShareModal newsModalData={newsModalData} closeModal={closeModal} newsOpenModal={newsOpenModal} />
            <div className="c-wrapper">
                {news?.items?.length > 0 ? (
                    <div className="c-item-news">
                        <h1 className="c-item-01">{words && words['WEB.NEWS']}</h1>

                        {news?.items?.map((item, index) => {
                            return (
                                <div key={'news' + index} className="c-item-02">
                                    <Link to={`/${words && words['WEB.newsDetails']}/${urlName?.items?.find(urlItem => urlItem.id === item?.id)?.url}`} className="c-item-02-A">
                                        <img loading="lazy" src={item?.imageSmall} className="c-unit-01" />
                                    </Link>
                                    <div className="c-item-02-B">
                                        <div className="c-unit-01">{item?.header}</div>
                                        <div className="c-unit-02">{item?.body}</div>
                                        <div className="c-unit-03">
                                            <Link to={`/${words && words['WEB.newsDetails']}/${urlName?.items?.find(urlItem => urlItem.id === item?.id)?.url}`} className="c-unit-03-A">
                                                {words && words['WEB.learnMore']} 
                                            </Link>
                                            <button onClick={() => newDetailModal(item?.id)} className="c-unit-03-B">
                                                <svg
                                                    className="share-icon"
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M19 4H15.8C14.1198 4 13.2798 4 12.638 4.32698C12.0735 4.6146 11.6146 5.07354 11.327 5.63803C11 6.27976 11 7.11984 11 8.8V10M19 4L16 1M19 4L16 7M8 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V14.2C1 15.8802 1 16.7202 1.32698 17.362C1.6146 17.9265 2.07354 18.3854 2.63803 18.673C3.27976 19 4.11984 19 5.8 19H14.2C15.8802 19 16.7202 19 17.362 18.673C17.9265 18.3854 18.3854 17.9265 18.673 17.362C19 16.7202 19 15.8802 19 14.2V12"
                                                        stroke="white"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                                <svg
                                                    className="share-icon-responsive"
                                                    width="40"
                                                    height="40"
                                                    viewBox="0 0 40 40"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M35 10H29.6667C26.8664 10 25.4663 10 24.3967 10.545C23.4559 11.0243 22.691 11.7892 22.2116 12.73C21.6667 13.7996 21.6667 15.1997 21.6667 18V20M35 10L30 5M35 10L30 15M16.6667 5H13C10.1997 5 8.79961 5 7.73005 5.54497C6.78924 6.02433 6.02433 6.78924 5.54497 7.73005C5 8.79961 5 10.1997 5 13V27C5 29.8003 5 31.2004 5.54497 32.27C6.02433 33.2108 6.78924 33.9757 7.73005 34.455C8.79961 35 10.1997 35 13 35H27C29.8003 35 31.2004 35 32.27 34.455C33.2108 33.9757 33.9757 33.2108 34.455 32.27C35 31.2004 35 29.8003 35 27V23.3333"
                                                        stroke="white"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div className="spinner-02">
                        <CircularProgress size={100} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default New;
