import Drawer from '@material-ui/core/Drawer';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { scrollToTop, userId } from '../../../_helpers/useful-functions';
import { useAppSelector } from '../../../store';
import { setAppCardDetailToOpen, setAppCardToClose, setDrawerMenuOpen } from '../../../store/features/hr/hrSlice';
import { isMacOs } from 'react-device-detect';

let scrollElement: any = document.getElementById('root');

const AsideMenu = () => {
    const { appCardToOpen, footerList, drawerOpen, isChangePage } = useAppSelector(state => state.hr);
    const { leftMenuList, urlName }: any = useAppSelector(state => state.hrDetails);
    const dispatch = useDispatch();
    const { words } = useAppSelector(state => state.localization);

    const handleDrawerOpen = () => {
        dispatch(setDrawerMenuOpen(true));
    };

    const handleDrawerClose = () => {
        dispatch(setDrawerMenuOpen(false));
        dispatch(setAppCardDetailToOpen(false));
    };

    const handleGoToDetailPage = (detailId = '',leftMenuType) => {
      if(leftMenuType !==4){
        scrollToTop();
      }
        dispatch(setDrawerMenuOpen(false));
        dispatch(setAppCardDetailToOpen(false));
        dispatch(setAppCardToClose(false));

    };

    const isHome = scrollElement?.classList?.contains('home');
    useEffect(() => {
        if (isHome) {
            scrollElement = window;
        }
        scrollElement.addEventListener('scroll', onScrolling);
    }, [isHome]);

    useEffect(() => {
        const asideMenuBar = document.getElementById('aside-menu-bar');
        const weatherConditionAndSocialMedia = document.getElementById('wheather-condition-social-media');
        const heroSlider = document.getElementById('hero-slider');
        const informationContent = document?.getElementById('information-content');

        const scrollHeroSliderTop = heroSlider?.getBoundingClientRect().top;
        const scrollInformationContentTop = informationContent?.getBoundingClientRect()?.top ?? -105;

        if (asideMenuBar && weatherConditionAndSocialMedia) {
            asideMenuBar.classList?.remove('asideMenuExpanded');
            asideMenuBar.classList?.remove('f-openDrawer');
            asideMenuBar.classList?.remove('f-closeDrawer');
            weatherConditionAndSocialMedia.classList?.remove('f-hide');
            asideMenuBar.classList?.remove('burgerMenu');

            if (appCardToOpen) {
                asideMenuBar.classList.add('burgerMenu');
                weatherConditionAndSocialMedia.classList.add('f-hide');

                if (drawerOpen && !asideMenuBar?.classList?.contains('f-openDrawer')) {
                    asideMenuBar?.classList?.add('f-openDrawer');
                    asideMenuBar?.classList?.remove('burgerMenu');
                } else if (
                    !drawerOpen &&
                    !asideMenuBar.classList.contains('f-closeDrawer') &&
                    !weatherConditionAndSocialMedia.classList.contains('f-hide')
                ) {
                    asideMenuBar.classList.add('f-closeDrawer');
                    weatherConditionAndSocialMedia.classList.add('f-hide');
                    asideMenuBar.classList?.remove('asideMenuExpanded');
                }
            } else {
                asideMenuBar?.classList?.remove('asideMenuExpanded');
                asideMenuBar?.classList?.remove('burgerMenu');
                weatherConditionAndSocialMedia?.classList?.remove('f-hide');
                asideMenuBar?.classList?.remove('center-close-icon');
                if (
                    drawerOpen &&
                    !asideMenuBar?.classList?.contains('center-close-icon') &&
                    !weatherConditionAndSocialMedia?.classList?.contains('f-hide')
                ) {
                    asideMenuBar?.classList.add('center-close-icon');
                    weatherConditionAndSocialMedia?.classList.add('f-hide');
                } else {
                    const contentTopValue = document?.getElementById('root').scrollTop;
                    if (informationContent && scrollInformationContentTop >= -150 && !asideMenuBar?.classList?.contains('asideMenuExpanded')) {
                        asideMenuBar?.classList?.add('asideMenuExpanded');
                    } else if (
                        informationContent &&
                        scrollInformationContentTop < -150 &&
                        !asideMenuBar?.classList?.contains('burgerMenu') &&
                        !weatherConditionAndSocialMedia?.classList?.contains('f-hide')
                    ) {
                        asideMenuBar?.classList?.add('burgerMenu');
                        weatherConditionAndSocialMedia?.classList?.add('f-hide');
                    } else if (heroSlider && scrollHeroSliderTop >= -150 && !asideMenuBar?.classList?.contains('asideMenuExpanded')) {
                        asideMenuBar?.classList?.add('asideMenuExpanded');
                    } else if (
                        heroSlider &&
                        scrollHeroSliderTop < -150 &&
                        !asideMenuBar?.classList?.contains('burgerMenu') &&
                        !weatherConditionAndSocialMedia?.classList?.contains('f-hide')
                    ) {
                        asideMenuBar?.classList?.add('burgerMenu');
                        weatherConditionAndSocialMedia?.classList?.add('f-hide');
                    } else if (
                        !heroSlider &&
                        !informationContent &&
                        contentTopValue < 200 &&
                        !asideMenuBar?.classList?.contains('asideMenuExpanded')
                    ) {
                        asideMenuBar?.classList?.remove('burgerMenu');
                        weatherConditionAndSocialMedia?.classList?.remove('f-hide');
                        asideMenuBar?.classList?.add('asideMenuExpanded');
                    } else if (
                        !heroSlider &&
                        !informationContent &&
                        contentTopValue > 201 &&
                        !asideMenuBar?.classList?.contains('burgerMenu') &&
                        !weatherConditionAndSocialMedia?.classList?.contains('f-hide')
                    ) {
                        asideMenuBar?.classList?.remove('asideMenuExpanded');
                        asideMenuBar?.classList?.add('burgerMenu');
                        weatherConditionAndSocialMedia?.classList?.add('f-hide');
                    }
                }
            }
        }
    }, [appCardToOpen, drawerOpen, isChangePage]);

    const onScrolling = e => {
        const asideMenuBar = document.getElementById('aside-menu-bar');
        const weatherConditionAndSocialMedia = document.getElementById('wheather-condition-social-media');
        const heroSlider = document.getElementById('hero-slider');
        const informationContent = document?.getElementById('information-content');

        const contentTopValue = e?.target?.scrollTop;

        const scrollHeroSliderTop = heroSlider?.getBoundingClientRect()?.top;

        if (
            heroSlider &&
            scrollHeroSliderTop <= -150 &&
            !asideMenuBar?.classList?.contains('burgerMenu') &&
            !weatherConditionAndSocialMedia?.classList?.contains('f-hide')
        ) {
            //! hero-slider varken burger menu
            asideMenuBar?.classList?.remove('asideMenuExpanded');
            asideMenuBar?.classList?.add('burgerMenu');
            weatherConditionAndSocialMedia?.classList?.add('f-hide');
        } else if (heroSlider && scrollHeroSliderTop > -150 && !asideMenuBar?.classList?.contains('asideMenuExpanded')) {
            //! hero-slider varken açık menu

            asideMenuBar?.classList?.remove('burgerMenu');
            asideMenuBar?.classList?.add('asideMenuExpanded');
            weatherConditionAndSocialMedia?.classList?.remove('f-hide');
        } else if (
            informationContent &&
            contentTopValue >= 300 &&
            !asideMenuBar?.classList?.contains('burgerMenu') &&
            !weatherConditionAndSocialMedia?.classList?.contains('f-hide')
        ) {
            //! detay sayfasında burger menu
            asideMenuBar?.classList?.remove('asideMenuExpanded');
            asideMenuBar?.classList?.add('burgerMenu');
            weatherConditionAndSocialMedia?.classList?.add('f-hide');
        } else if (informationContent && contentTopValue < 300 && !asideMenuBar?.classList?.contains('asideMenuExpanded')) {
            //! detay sayfasında açık menu

            asideMenuBar?.classList?.remove('burgerMenu');
            weatherConditionAndSocialMedia?.classList?.remove('f-hide');
            asideMenuBar?.classList?.add('asideMenuExpanded');
        } else if (!heroSlider && !informationContent && contentTopValue < 200 && !asideMenuBar?.classList?.contains('asideMenuExpanded')) {
            //! ana sayfa ve detay sayfalar haric diger sayfalar kvkk ve cerez gibi burger menu
            asideMenuBar?.classList?.remove('burgerMenu');
            weatherConditionAndSocialMedia?.classList?.remove('f-hide');
            asideMenuBar?.classList?.add('asideMenuExpanded');
        } else if (
            !heroSlider &&
            !informationContent &&
            contentTopValue > 201 &&
            !asideMenuBar?.classList?.contains('burgerMenu') &&
            !weatherConditionAndSocialMedia?.classList?.contains('f-hide')
        ) {
            //! ana sayfa ve detay sayfalar haric diger sayfalar kvkk ve çerez gibi acik menu
            asideMenuBar?.classList?.remove('asideMenuExpanded');
            asideMenuBar?.classList?.add('burgerMenu');
            weatherConditionAndSocialMedia?.classList?.add('f-hide');
        }
    };

    //!wheather  start
    const icon = document?.querySelector('.ww_icon');
    const tempIconEl = document?.getElementById('temp-icon');
    if (tempIconEl && !tempIconEl?.innerHTML && icon) {
        tempIconEl.appendChild(icon);
    }
    const temperature = document?.querySelector('.ww_temp')?.innerHTML?.trim();
    //!wheather  end

    return (
        <>
            <Drawer className={isMacOs ? "drawer-01 isMacOs" : "drawer-01" } variant="persistent" anchor="left" open={drawerOpen}>
                <div className="drawer-responsive">
                    <button className="close-icon-responsive" onClick={handleDrawerClose}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.23021e-07 1.54286L1.54286 0L18 16.4571L16.4571 18L4.23021e-07 1.54286Z" fill="white" />
                            <path d="M16.4571 0L18 1.54286L1.54286 18L0 16.4571L16.4571 0Z" fill="white" />
                        </svg>
                    </button>
                </div>
                <div className="c-wrapper">
                    <div className="c-container">
                        {leftMenuList?.items?.length > 0 && !urlName?.loading &&
                            leftMenuList?.items?.map((item: any, index: number) => (
                                <div className="c-item" key={'leftMenuList' + index}>
                                    <svg className="c-item-01" width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle opacity="0.5" r="3" transform="matrix(-1 0 0 1 3 3)" fill="white" />
                                    </svg>
                                    <Link
                                        className="c-item-02"
                                        target={item?.leftMenuType === 4 || item?.leftMenuType === 5 ? '_blank' : ''}
                                        rel="noreferrer"
                                        to={{
                                            pathname:
                                                item?.leftMenuType === 0
                                                    ? `/${words && words['WEB.appDetails']}/${urlName?.items?.find(urlItem => urlItem.id === item?.id)?.url}`
                                                    : item?.leftMenuType === 1
                                                    ? `/${words && words['WEB.NEWS']}`
                                                    : item?.leftMenuType === 3
                                                    ? `/${words && words['WEB.TOBEKOC']}`
                                                    : item?.url,
                                        }}
                                        onClick={() => handleGoToDetailPage(item.id,item?.leftMenuType)}>
                                        {item?.name || ''} 
                                    </Link>
                                </div>
                            ))}
                    </div>
                </div>
            </Drawer>
            <div className={isMacOs ? "aside-menu isMacOs" : "aside-menu"} id="aside-menu-bar">
                <div></div>
                <div className="c-container-01">
                    {!drawerOpen ? (
                        <button type="button" onClick={handleDrawerOpen}>
                            <svg width="56" height="19" viewBox="0 0 56 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <line y1="1.5" x2="56" y2="1.5" stroke="white" strokeWidth="3" />
                                <line y1="17.5" x2="56" y2="17.5" stroke="white" strokeWidth="3" />
                            </svg>
                        </button>
                    ) : (
                        <button type="button" onClick={handleDrawerClose}>
                            <svg width="54" height="52" viewBox="0 0 54 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M26.8701 26.8701L51.6188 51.6188L53.7401 49.4975L28.9914 24.7488L51.6188 2.12141L49.4975 8.47578e-05L26.8701 22.6275L4.24264 0L2.12132 2.12132L24.7488 24.7488L0 49.4976L2.12132 51.6189L26.8701 26.8701Z"
                                    fill="white"
                                />
                            </svg>
                        </button>
                    )}
                </div>

                <div className="c-container-02" id="wheather-condition-social-media">
                    <div className="c-item-social-icons">
                        {footerList?.items?.socialMedia?.length > 0 &&
                            footerList?.items?.socialMedia?.map(item => (
                                <a key={item?.key} href={item?.value} className="c-item" target="_blank" rel="noreferrer">
                                    <img loading="lazy" className="c-item-01" src={item?.iconUrlLeftMenu} />
                                </a>
                            ))}
                    </div>
                    {userId && temperature && icon && (
                        <div className="c-item-weather-condition">
                            <div id="temp-icon"></div>
                            <span className="c-item-temp">{temperature}</span>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default AsideMenu;
