import { toastMessage } from '@kocsistem/oneframe-react-bundle';
import React, { useEffect, useState } from 'react';
import { isMacOs } from 'react-device-detect';
import { useDispatch } from 'react-redux';

import { getDateFormat, userId } from '../../_helpers/useful-functions';
import { post } from '../../core/client';
import { MESSAGE_TYPE } from '../../core/constants';
import { getLocalStorageItem, removeLocalStorageItem } from '../../core/utility';
import { useAppSelector } from '../../store';
import {
    getAllHobyOptions,
    getAllCelebrationOptions,
    getAllCityOptions,
    getAllUserPetOptions,
    getAllCelebrateWhomOptions,
    getBasketballTeamsOptions,
    getVoleyballTeamsOptions,
    getFootballTeamsOptions,
    getOtherTeamsOptions,
    getProfileUserDataById,
    setFullnessRatio,
} from '../../store/features/profile/profileSlice';
import { getUserInfo, getUserKvkk } from '../../store/features/user/usersSlice';
import KvkkModal from '../home/KvkkModal';
import Address from './address';
import CelebrateDays from './celebrateDays';
import InterestsAndHobbies from './interestsAndHobbies';
import PersonalInfo from './personalInfo';
import Teams from './teams';
import { CircularProgress } from '@material-ui/core';

const Profile = () => {
    const [userInfo, setUserInfo] = useState(null);
    const [isCelebratingSpecialDay, setIsCelebratingSpecialDay] = useState(false);
    const [isCreateProfileLoading, setIsCreateProfileLoading] = useState<boolean>(false);
    const [openKvkkModal, setOpenKvkkModal] = useState<boolean>(false);
    const [isTouched, setIsTouched] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(true);
    const [submitUpdate, setSubmitUpdate] = useState<number>(1);
    const { words } = useAppSelector(state => state.localization);
    const { info, Kvkk }: any = useAppSelector(state => state.users);
    const { joy, teams, celebrate, address }: any = useAppSelector(state => state.profile.profileData);
    const { profileUserInfo }: any = useAppSelector(state => state.profile.profileUserData);


    const culture = getLocalStorageItem('SelectedLanguage');
    removeLocalStorageItem('paramsFromURL')
    const dispatch = useDispatch();
    
    useEffect(() => {
        if (userId) {
            dispatch(getUserKvkk());
            dispatch(getProfileUserDataById(userId));
            dispatch(getUserInfo());
        }
        dispatch(getAllHobyOptions());
        dispatch(getAllCelebrationOptions());
        dispatch(getAllCelebrateWhomOptions());
        dispatch(getAllCityOptions());
        dispatch(getAllUserPetOptions());
        dispatch(getBasketballTeamsOptions());
        dispatch(getVoleyballTeamsOptions());
        dispatch(getFootballTeamsOptions());
        dispatch(getOtherTeamsOptions());
    }, [culture]);

    useEffect(() => {
        dispatch(setFullnessRatio(info?.userInfo?.fullnessRatio));
    }, [info?.userLoading]);

    const isEdit = JSON.parse(localStorage.getItem('USER_ID')) === userId;

    useEffect(() => {
        setOpenKvkkModal(profileUserInfo?.showProfilePopup || false);
    }, [profileUserInfo])

    useEffect(() => {
        if (!isValid && isTouched) {
            const firstInValidElm = document.querySelector('#editProfileForm .error') || document.querySelector('#f-datepicker.f-birthdate-error');
            firstInValidElm?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        }
    }, [isValid, isTouched, submitUpdate]);

    const profileHandleSubmit = async () => {
        let isValid = true;

        setIsTouched(true);
        const profileFormData = {
            userId,
            isCelebratingSpecialDay,
            userPersonalInfoAddDto: {
                maritalStatus: null,
                spouseLastname: '',
                spouseFirstname: '',
                dateofMarriage: null,
                numberofChilds: 0,
                hasPetType: null,
                hasChildType: null,
                celebrateBirthdate: null,
            },
            userChildren: [
                {
                    firstName: '',
                    gender: null,
                    birthDate: null,
                    id: null,
                },
            ],
            userPets: [
                {
                    breedId: null,
                    name: '',
                    birthDate: null,
                    petBreedName: '',
                    id: null,
                },
            ],
            userJoyAdds: joy,
            userAddressAddDto: address,
            createUserFavoriteTeams: teams,
            userCelebratesForOthers: celebrate,
        };

        //!userPersonalInfoAddDto
        profileFormData.userPersonalInfoAddDto.maritalStatus = parseInt(userInfo.maritalStatus);
        if (parseInt(userInfo.maritalStatus) === 1) {
            profileFormData.userPersonalInfoAddDto.spouseFirstname = userInfo?.spouseFirstname ? userInfo?.spouseFirstname : '';
            profileFormData.userPersonalInfoAddDto.spouseLastname = userInfo?.spouseLastname ? userInfo?.spouseLastname : '';
        }

        //!dateOfMarriage check
        if (parseInt(userInfo.maritalStatus) === 1) {
            if (new Date(userInfo.dateOfMarriage).toDateString() === 'Invalid Date') {
                isValid = false;
            } else if (getDateFormat({ date_: userInfo.dateOfMarriage }) === 'Invalid Date') {
                isValid = false;
            } else if (userInfo.dateOfMarriage !== null) {
                profileFormData.userPersonalInfoAddDto.dateofMarriage = getDateFormat({ date_: userInfo.dateOfMarriage });
            }
        }
        //!celebrateBirthdate check
        if (new Date(userInfo.celebrateBirthdate).toDateString() === 'Invalid Date') {
            isValid = false;
        } else if (getDateFormat({ date_: userInfo.celebrateBirthdate }) === 'Invalid Date') {
            isValid = false;
        } else if (userInfo.celebrateBirthdate !== null) {
            profileFormData.userPersonalInfoAddDto.celebrateBirthdate = getDateFormat({ date_: userInfo.celebrateBirthdate });
        }
        profileFormData.userPersonalInfoAddDto.hasPetType = userInfo?.hasPetType;
        profileFormData.userPersonalInfoAddDto.hasChildType = userInfo?.hasChildType;

        profileFormData.userPersonalInfoAddDto.numberofChilds = userInfo.childCountInfo ? userInfo.childCountInfo : 0;
        //! userChildren
        if (userInfo.userChildBirthDates[0] !== null && userInfo.userChildBirthDates.length > 0 && parseInt(userInfo.hasChildType) === 1) {
            const allUserChildBirthDates = [];
            for (let i = 0; i < userInfo.userChildBirthDates.length; i++) {
                const allUserChildBirthDate = userInfo.userChildBirthDates[i];
                allUserChildBirthDates.push({
                    id: allUserChildBirthDate?.userChildId ? allUserChildBirthDate.userChildId : null,
                    firstName: allUserChildBirthDate?.userChildName,
                    gender: parseInt(allUserChildBirthDate?.gender),
                    birthDate: allUserChildBirthDate?.userChildBirthDate ? getDateFormat({ date_: allUserChildBirthDate.userChildBirthDate }) : '',
                });
            }
            profileFormData.userChildren = allUserChildBirthDates;
        }

        // isvalid time scroll
        if (profileFormData.userChildren.find(item => item.birthDate === 'NaN-NaN-NaN' || item.birthDate === 'Invalid Date')) {
            isValid = false;
        }

        if (profileFormData?.userChildren?.find(item => item.birthDate === '')) {
            isValid = false;
        }

        if (userInfo?.userChildBirthDates?.some(item => !item)) {
            isValid = false;
        }

        //! userPets
        if (userInfo.userPetBirthDates[0] !== null && userInfo.userPetBirthDates.length > 0 && parseInt(userInfo.hasPetType) === 1) {
            const allUserPetBirthDates = [];
            for (let i = 0; i < userInfo.userPetBirthDates.length; i++) {
                const allUserPetBirthDate = userInfo.userPetBirthDates[i];
                allUserPetBirthDates.push({
                    id: allUserPetBirthDate.userPetId ? allUserPetBirthDate.userPetId : null,
                    breedId: allUserPetBirthDate?.userPetBreedId ? allUserPetBirthDate?.userPetBreedId : null,
                    name: allUserPetBirthDate?.userPetName ? allUserPetBirthDate.userPetName : '',
                    birthDate:
                        allUserPetBirthDate?.userPetBirthDate?.length === 0
                            ? ''
                            : allUserPetBirthDate?.userPetBirthDate
                                ? getDateFormat({ date_: allUserPetBirthDate.userPetBirthDate })
                                : null,
                    petBreedName: allUserPetBirthDate?.petBreedName ? allUserPetBirthDate.petBreedName : '',
                });
            }
            profileFormData.userPets = allUserPetBirthDates;
        }
        // is it valid time or scroll
        if (profileFormData.userPets.find(item => item.birthDate === 'NaN-NaN-NaN' || item.birthDate === 'Invalid Date')) {
            isValid = false;
        }
        if (profileFormData?.userPets?.find(item => item.birthDate === '')) {
            isValid = false;
        }

        if (userInfo?.userPetBirthDates?.some(item => !item)) {
            isValid = false;
        }

        //!marry status
        if (parseInt(userInfo.maritalStatus) === 1) {
            if (
                !userInfo.spouseFirstname ||
                userInfo.spouseFirstname === '' ||
                !userInfo.spouseLastname ||
                userInfo.spouseLastname === '' ||
                !userInfo.dateOfMarriage
            ) {
                isValid = false;
            }
        }
        //!children
        if (userInfo?.userChildBirthDates[0] !== null && userInfo.userChildBirthDates.length > 0) {
            if (userInfo.userChildBirthDates?.some(x => !x?.userChildId) && parseInt(userInfo.hasChildType) === 1) {
                userInfo.userChildBirthDates.find(item => {
                    if (!item?.userChildName || !item?.gender || !item?.userChildBirthDate) {
                        isValid = false;
                    }
                });
            } else {
                if (parseInt(userInfo.hasChildType) === 1) {
                    userInfo.userChildBirthDates.find(item => {
                        if (!item.userChildName || !item.gender || !item.userChildBirthDate) {
                            isValid = false;
                        }
                    });
                }
            }
        } else if (userInfo?.userChildBirthDates.length === 0 || userInfo.userChildBirthDates[0] === null) {
            if (parseInt(userInfo.hasChildType) === 1) {
                isValid = false;
            }
        }

        if (userInfo.userChildBirthDates?.length === 0 && userInfo.hasChildType === '1') {
            isValid = false;
        }
        //!pets
        if ((userInfo?.userPetBirthDates.length === 0 || userInfo?.userPetBirthDates[0] === null) && userInfo.hasPetType === '1') {
            isValid = false;
        }
        if (userInfo.userPetBirthDates[0] !== null && userInfo.hasPetType === '1') {
            if (userInfo?.userPetBirthDates?.some(x => !x?.isSelected && (x?.userPetName === '' || !x?.userPetBreedId))) {
                isValid = false;
            } else if (userInfo?.userPetBirthDates?.some(x => x?.isOther && x?.petBreedName === '')) {
                isValid = false;
            }
        } else if (userInfo.userPetBirthDates?.length === 0 && userInfo.hasPetType === '1') {
            isValid = false;
        } else if (userInfo.userPetBirthDates[0] === null && userInfo.hasPetType === '1') {
            isValid = false;
        }
        //!hobby
        if (joy?.length > 0) {
            if (joy?.some(item => item.joyTypeId)) {
                joy?.find(item => {
                    if (!item.joyId) isValid = false;
                });
            }
            if (joy?.some(item => item.joyId)) {
                joy?.find(item => {
                    if (!item?.joyTypeId && !item?.name) isValid = false;
                });
            }
        }

        if (joy?.length > 0) {
            if (joy?.some(x => x?.isOther && !x?.name)) {
                isValid = false;
            }
        }

        if (joy?.length === 1 && (joy[0]?.joyId === '' || joy[0]?.joyId === null)) isValid = false;
        if (joy?.length === 1 && (joy[0]?.joyTypeId === '' || joy[0]?.joyTypeId === null)) isValid = false;

        //!celebrate
        if (celebrate?.length > 0) {
            if (celebrate.some(x => x?.specialDateId)) {
                celebrate.find(item => {
                    if (!item.celebrationForWhomId) isValid = false;
                });
            }
            if (celebrate?.some(item => item.celebrationForWhomId)) {
                celebrate?.find(item => {
                    if (!item.specialDateId && !item.name) isValid = false;
                });
            }
        }

        if (celebrate?.length === 1 && (celebrate[0].celebrationForWhomId === '' || celebrate[0].celebrationForWhomId === null)) isValid = false;
        if (celebrate?.length === 1 && (celebrate[0].specialDateId === '' || celebrate[0].specialDateId === null)) isValid = false;
        if (celebrate?.length > 0) {
            if (celebrate?.some(x => x?.isOther && x?.name === '')) {
                isValid = false;
            }
        }

        //! teams
        if (teams?.length > 0) {
            if (teams?.some(x => x?.isOther && !x?.name)) {
                isValid = false;
            }
        }
        // address

        if (address.cityId) {
            if (!address.address || !address.countyId) {
                isValid = false;
            }
        } else if (address.countyId) {
            if (!address.address || !address.cityId) {
                isValid = false;
            }
        } else if (address.address.length > 0) {
            if (!address.countyId || !address.cityId) {
                isValid = false;
            }
        }

        if (!isValid) {
            setIsValid(false);
            setSubmitUpdate(submitUpdate + 1);
            return;
        } else {
            setIsValid(true);
        }

        setIsCreateProfileLoading(true);

        const endpoint = `/users/create-user-profile`;
        await post<any>(endpoint, profileFormData, '').then(res => {
            if (res?.isSuccessful) {
                dispatch(getUserInfo());
                dispatch(getProfileUserDataById(profileFormData?.userId));
                setIsCreateProfileLoading(false);
                toastMessage({
                    messageType: MESSAGE_TYPE.SUCCESS,
                    message: words && words['WEB.saveProfileSuccessfully'],
                    position: 'topRight',
                    icon: 'flaticon-like',
                    timeout: 2000,
                });

            } else {
                setIsCreateProfileLoading(false);
                toastMessage({
                    messageType: MESSAGE_TYPE.WARNING,
                    message: res?.error?.message,
                    position: 'topRight',
                    icon: 'flaticon-warning-sign',
                    timeout: 6000,
                });
            }
        });
    };
  
    return (
        <>{info?.userInfo ?
            <div className={isMacOs ? 'section-03 isMacOs' : 'section-03'}>
                {openKvkkModal && Kvkk?.items?.text && (
                    <KvkkModal setOpenKvkkModal={setOpenKvkkModal} openKvkkModal={openKvkkModal} />
                )}
                <div className="c-container-01">
                    <div className="c-wrapper">
                        <form className="profile-upload-form" id="editProfileForm">
                            <PersonalInfo setUserInformation={setUserInfo} isEdit={isEdit} isTouched={isTouched} />
                            <InterestsAndHobbies isEdit={isEdit} isTouched={isTouched} />
                            <Teams isEdit={isEdit} isTouched={isTouched} />
                            <CelebrateDays setIsCelebratingSpecialDay={setIsCelebratingSpecialDay} isEdit={isEdit} isTouched={isTouched} />
                            <Address isEdit={isEdit} isTouched={isTouched} />
                            {isEdit && (
                                <div className="c-save-div">
                                    <a
                                        className={isCreateProfileLoading ? 'button-01 disabled' : 'button-01'}
                                        href="#!"
                                        onClick={profileHandleSubmit}>
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M19.5938 21.9375H2.40625C1.11182 21.9375 0.0625 20.8882 0.0625 19.5938V2.40625C0.0625 1.11182 1.11182 0.0625 2.40625 0.0625H19.5938C20.8882 0.0625 21.9375 1.11182 21.9375 2.40625V19.5938C21.9375 20.8882 20.8882 21.9375 19.5938 21.9375ZM9.59932 17.1495L18.5837 8.16509C18.8888 7.86001 18.8888 7.36533 18.5837 7.06025L17.4789 5.95542C17.1738 5.65034 16.6791 5.65029 16.374 5.95542L9.04688 13.2825L5.62603 9.86162C5.32095 9.55654 4.82627 9.55654 4.52114 9.86162L3.41631 10.9665C3.11123 11.2715 3.11123 11.7662 3.41631 12.0713L8.49443 17.1494C8.79956 17.4546 9.29419 17.4546 9.59932 17.1495Z"
                                                fill="white"
                                            />
                                        </svg>
                                        <span className="text-01">{words && words['WEB.SAVE']}</span>
                                    </a>
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            </div>
            :
            <div className='d-flex align-items-center justify-content-center h-100'>
                <CircularProgress size={80}/>
            </div>
        }
        </>
    );
};

export default Profile;
