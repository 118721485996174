import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { get } from '../../../core/client';
import { IHrDetails, IHrUrlName } from './model';

export const getLeftMenuList: any = createAsyncThunk('hr/getLeftMenuList', async () => {
    const leftMenuList = await get<any>('hr/get-ikcati-left-menu-list');
    return leftMenuList;
});
export const getApplicationDetailsListForDetails: any = createAsyncThunk('hr/getApplicationDetailsListForDetails', async () => {
    const getApplicationDetailsListForDetails_ = await get<any>('hr/get-application-detail-list');
    return getApplicationDetailsListForDetails_;
});

export const getLeftMenuListForDetail: any = createAsyncThunk('hr/getLeftMenuDetailsList', async (detailId: any) => {
    const getLeftMenuListForDetail_ = await get<any>(`hr/get-ikcati-left-menu-detail-by-id?leftMenuId=${detailId}`);
    return getLeftMenuListForDetail_;
});

export const getCardDetail: any = createAsyncThunk('hr/getCardDetail', async (cardId: any) => {
    const getCardDetail_ = await get<any>(`hr/get-ikcati-card-detail-by-id?cardId=${cardId}`);
    return getCardDetail_;
});

export const getToBeKocDetails: any = createAsyncThunk('hr/getToBeKocDetails', async () => {
    const baseUrl = 'hr/get-detail-left-menu?id=7915eab2-8906-47f5-8014-12f324b142a6';
    const getToBeKocDetails_ = await get<any>(baseUrl);
    return getToBeKocDetails_;
});

export const getUrlName: any = createAsyncThunk('hr/getUrlName', async () => {
    const urlName = await get<any>('hr/get-url-name');
    return urlName;
});

const hrDetailSlice = createSlice({
    name: 'hrDetails',
    initialState: {
        leftMenuList: {
            loading: true,
            items: [],
        },
        applicationDetailsList: {
            loading: true,
            items: [],
        },
        leftMenuDetailList: {
            loading: true,
            items: [],
        },
        cardDetailList: {
            loading: true,
            items: null,
        },
        toBeKocDetails: {
            loading: true,
            item: null,
            items: null,
            imageItemLeng: null,
            ikCatiLeftMenuWidgetMedias: []
        },
        appCardDetailIndex: 1,
        urlName: {
            loading: true,
            items: [],
        },
    },
    reducers: {
        setAppCardDetailIndex(state, action) {
            state.appCardDetailIndex = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(getLeftMenuList.pending || getLeftMenuList.rejected, state => {
            state.leftMenuList.loading = true;
        });
        builder.addCase(getLeftMenuList.fulfilled, (state, action: PayloadAction<IHrDetails>) => {
            state.leftMenuList.items = action?.payload.isSuccessful ? action.payload.result.items : [];
            state.leftMenuList.loading = false;
        });
        builder.addCase(getApplicationDetailsListForDetails.fulfilled, (state, action: PayloadAction<IHrDetails>) => {
            state.applicationDetailsList.items = action?.payload.isSuccessful ? action.payload.result.items : [];
            state.applicationDetailsList.loading = false;
        });
        builder.addCase(getLeftMenuListForDetail.fulfilled, (state, action: PayloadAction<IHrDetails>) => {
            state.leftMenuDetailList.items = action?.payload.isSuccessful ? action.payload.result.item : [];
            state.leftMenuDetailList.loading = false;
        });
        builder.addCase(getCardDetail.pending || getCardDetail.rejected, state => {
            state.cardDetailList.loading = true;
        });
        builder.addCase(getCardDetail.fulfilled, (state, action: PayloadAction<IHrDetails>) => {
            state.cardDetailList.items = action?.payload.isSuccessful ? action.payload.result.item : null;
            state.cardDetailList.loading = false;
        });
        builder.addCase(getToBeKocDetails.pending || getToBeKocDetails.rejected, state => {
            state.toBeKocDetails.loading = true;
        });
        builder.addCase(getToBeKocDetails.fulfilled, (state, action: any) => {
            state.toBeKocDetails.item = action?.payload?.isSuccessful ? action.payload.result.item : [];
            state.toBeKocDetails.items = action?.payload?.isSuccessful ? action.payload.result.item : [];
            state.toBeKocDetails.loading = false;
            state.toBeKocDetails.ikCatiLeftMenuWidgetMedias = action?.payload?.result?.item?.ikCatiLeftMenuWidgetMedias;
            state.toBeKocDetails.imageItemLeng = action?.payload?.result?.item?.ikCatiLeftMenuWidgetMedias?.length;
        });
        builder.addCase(getUrlName.pending || getUrlName.rejected, state => {
            state.urlName.loading = true;
        });
        builder.addCase(getUrlName.fulfilled, (state, action: PayloadAction<IHrUrlName>) => {
            state.urlName.items = action?.payload.isSuccessful ? action.payload.result : [];
            state.urlName.loading = false;
        });
    },
});

export default hrDetailSlice.reducer;
export const { setAppCardDetailIndex } = hrDetailSlice.actions;
